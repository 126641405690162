import "./SeguimientoYMonitoreo.css"
import CompartirLinkModal from "./CompartirLinkModal";
import FiltrarModal from "./Filtros/FiltrarModal";
import InformacionViajeModal from "./informacionViaje/InformacionViajeModal";
import React, {useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {useSeguimientoMonitoreo} from "./SeguimientoMonitoreoProvider";
import {
    BorrarFiltrosIcon,
    CopiarViajesIcon, ScheduleIcon,
    ShareLinkIcon, WorksheetIcon
} from "../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import SeguimientoMonitoreoProvider from "./SeguimientoMonitoreoProvider";
import SeguimientoMonitoreoMapa from "./mapas/SeguimientoMonitoreoMapa";
import {OverlayPanel} from "primereact/overlaypanel";
import {
    colorIndicadores,
    filtroOpciones,
    nombreColorIndicador,
    opcionUnidades,
    tipoIndicador
} from "./SeguimentoConstantes";
import {MultiStateCheckbox} from "primereact/multistatecheckbox";
import TooltipGenerico from "./indicadores/TooltipGenerico";
import TooltipSignal from "./indicadores/TooltipSignal";
import IndicadoresClickOverlay from "./indicadores/IndicadoresClickOverlay";
import IndicadoresProvider, {useIndicadores} from "./indicadores/IndicadoresProvider";
import SeguimientoViaje from "./mapas/SeguimientoViaje";
import TotalIndicadores from "./indicadores/TotalIndicadores";
import TarjetasViaje from "./TarjetasViaje";
import ControlMapProvider from "./ControlMapProvider";
import SeleccionGeocerca from "./mapas/SeleccionGeocerca";
import RoutingModal from "./mapas/RoutingModal";
import SeleccionViajesCuentaEspejo from "./informacionViaje/SeleccionViajesCuentaEspejo";
import {convertirMinutosATexto, numberFormat, utcToString} from "../../../shared/format";
import constantes from "../../../administracion/constantes";
import {SelectButton} from "primereact/selectbutton";
import useApiViajeSeguimientoMonitoreo from "../../../api/operaciones/useApiSeguimientoMonitoreo";
import CompartirInformacionModal from "./Componentes/CompartirInformacionModal";

const {enRuta, programado, sinViaje} = constantes.estatusViajes;


const SeguimientoMonitoreo = ({soloUnidades}) => {


    const op = useRef(null);
    const [tooltip, setTooltip] = useState({tipo: tipoIndicador.velocidad, viaje: null, datos: {}})
    const [indicador,] = useState({tipo: tipoIndicador.velocidad, viaje: null, target: null})
    // const dispatch = useLoadingDispatch();
    const [visibleCompartir, setVisibleCompartir] = useState(false)
    const [visibleCompartirInformacion, setVisibleCompartirInformacion] = useState(false)
    const api = useApiViajeSeguimientoMonitoreo();

    const {
        filtro,
        todosVisibles,
        cambiarTodosVisibles,
        viajesEnSeguimiento,
        resetearFiltros,
        mostrarFiltro,
        aplicarFiltro,
        mostrar,
        onChangeMostrar,
        contadorUnidades
    } = useSeguimientoMonitoreo();


    const {value: filter, setValue: onChangeFilter} = filtro;
    const {anchorInformacionViajeRef} = useIndicadores()

    const options = [
        {value: 'algunos', icon: 'pi pi-circle'},
        {value: 'todos', icon: 'pi pi-check'},
    ];

    const handleTooltip = async (e, tipo, viaje) => {
        op.current.toggle(e)
        let datos = {}
        let indicador = (viaje.indicadores ?? {})[tipo] ?? {};
        let datosAdicionales = (viaje.indicadoresAdicional ?? {})[tipo] ?? indicador;
        const color = indicador?.color
        const minutos = Math.round(datosAdicionales?.minutos ?? 0)

        const tiempo = color === nombreColorIndicador.gris || (color === nombreColorIndicador.verde && viaje.velocidad > 5) || color == null ?
            '' :
            `${convertirMinutosATexto(minutos)}`;

        switch (tipo) {
            case tipoIndicador.velocidad:
                datos = {
                    label: 'velocidad: ',
                    value: `${viaje?.velocidad??0} km/hr${tiempo !== '' ? `, En ${colorIndicadores[color]} por ${tiempo}` : ''}`
                }
                break;
            case tipoIndicador.lastPosition:
                datos = {label: 'Ubicación: ', value: viaje.ubicacion,indicador: viaje.eta};
                break;
            case tipoIndicador.motor:
                datos = {
                    label: 'Ralenty:', value: tiempo === '' ?
                        (color === nombreColorIndicador.gris ? 'Motor Apagado' : 'En Movimiento') :
                        `Encendido desde hace ${tiempo}`
                };
                break;
            case tipoIndicador.diesel:
                datos = {
                    label: 'Ubicacion', value: indicador.ubicacion,
                    label2: 'Fecha', value2: utcToString(indicador.fecha)
                };
                break;
            case tipoIndicador.botonPanico:
                datos = {
                    label: 'Ubicacion', value: indicador.ubicacion,
                    label2: 'Fecha', value2: utcToString(indicador.fecha)
                };
                break
            case tipoIndicador.horasManejo:
                datos = {
                    label: 'Horas de Descanso: ', value: numberFormat(viaje?.horasDescanso??0,0)
                };
                break
            default:
                break;
        }

        setTooltip({
                tipo,
                viaje,
                datos
            }
        )
    }

    const onHideTooltip = () => {
        op.current.hide()
    }

    const handleChange = (event) => {
        let texto = event.target.value
        onChangeFilter({...filter, texto});
    }

    const onChangeStatusFilter = async () => {

        const nuevoFiltro = {
            ...filtro.value
            , estatusId: visibleProgramados() ?
                enRuta :
                programado,
        }

        onChangeFilter(nuevoFiltro);
        await aplicarFiltro(nuevoFiltro);
    }

    const visibleProgramados = () => {
        return filtro.value.estatusId === constantes.estatusViajes.programado
    }

    const aplicarFiltroEstatus = (estatusId, enMantenimiento)=>{
        const nuevoEsatusId = filtro.value.estatusViajeUnidadId === estatusId ? null : estatusId
        const nuevoFiltro = {...filtro.value,estatusViajeUnidadId:nuevoEsatusId, enMantenimiento:enMantenimiento ?? false }
        onChangeFilter(nuevoFiltro);
        aplicarFiltro(nuevoFiltro)
    }

    const excel =()=>{
        api.excel(filtro)
    }


    return (<>
        <div className={'seg-seleccionar-tipo'}>
            {!soloUnidades &&
                <SelectButton value={mostrar} optionLabel="name" options={filtroOpciones}
                              onChange={(e) => onChangeMostrar(e.value)}/>
            }

            {mostrar.name === opcionUnidades &&
                <>
                    <span
                        className={`link ${filtro.value.estatusViajeUnidadId === enRuta ? 'seg-estatus-seleccionado' : ''}`}
                        onClick={() => aplicarFiltroEstatus(enRuta)}>
                        En Ruta:
                    </span>
                    <span className={'bold'}>
                        {contadorUnidades.enRuta}
                    </span>
                    <span
                        className={`link ${filtro.value.estatusViajeUnidadId === programado ? 'seg-estatus-seleccionado' : ''}`}
                        onClick={() => aplicarFiltroEstatus(programado)}>
                        Programadas:
                    </span>
                    <span className={'bold'}>
                        {contadorUnidades.programadas}
                    </span>
                    <span
                        className={`link ${filtro.value.estatusViajeUnidadId === sinViaje ? 'seg-estatus-seleccionado' : ''}`}
                        onClick={() => aplicarFiltroEstatus(sinViaje)}>
                        Sin Viaje:
                    </span>
                    <span className={'bold'}>
                        {contadorUnidades.sinViaje}
                    </span>
                    <span
                        className={`link ${filtro.value.estatusViajeUnidadId === sinViaje ? 'seg-estatus-seleccionado' : ''}`}
                        onClick={() => aplicarFiltroEstatus(null, true)}>
                        En Mantenimiento:
                    </span>
                    <span className={'bold'}>
                        {contadorUnidades.enMantenimiento}
                    </span>
                </>
            }
        </div>
        <div className={'seg-principal'}>
            <div style={{display: "flex", flexDirection: 'column'}}>
                <div className={'seg-filtro'}>
                    <div className={'seg-seleccionar'} ref={anchorInformacionViajeRef}>
                        <MultiStateCheckbox value={todosVisibles()}
                                            options={options} optionValue="value"
                                            onChange={(e) => cambiarTodosVisibles(e.value)}/>
                    </div>
                    <div className={'seg-filtro-text'}>
                        <span className="p-input-icon-left" style={{width: '100%'}}>
                            <i className="pi pi-sliders-h seg-mostrar-filtros" onClick={mostrarFiltro}/>
                                <InputText
                                    style={{width: '100%'}}
                                    onChange={handleChange}
                                    value={filter.texto ?? ''}
                                    maxLength={255}
                                />
                        </span>
                    </div>
                    <ScheduleIcon onClick={() => onChangeStatusFilter()}
                                  tooltip={`Mostrar viajes ${visibleProgramados() ? 'en ruta' : 'programados'}`}
                                  selected={visibleProgramados()}
                    />

                    <BorrarFiltrosIcon onClick={resetearFiltros}/>
                    <CopiarViajesIcon onClick={()=>setVisibleCompartirInformacion(true)}/>
                    <ShareLinkIcon onClick={() => setVisibleCompartir(true)}/>
                    <WorksheetIcon onClick={excel}/>
                </div>
                <TotalIndicadores soloUnidades={soloUnidades}/>
                <TarjetasViaje handleTooltip={handleTooltip} onHideTooltip={onHideTooltip} soloUnidades={soloUnidades}/>
            </div>
            <div style={{flexGrow: 1, position: 'relative'}}>
                <FiltrarModal/>
                <InformacionViajeModal/>
                <SeguimientoMonitoreoMapa/>
                <SeleccionGeocerca/>
                <RoutingModal/>
                <SeleccionViajesCuentaEspejo visible={visibleCompartir} onCancel={() => setVisibleCompartir(false)}/>
            </div>
        </div>
        <CompartirLinkModal/>
        <CompartirInformacionModal visibleCompartir={visibleCompartirInformacion} onClose={()=>setVisibleCompartirInformacion(false)}/>
        <IndicadoresClickOverlay indicador={indicador}/>
        <OverlayPanel ref={op} style={{width: '300px'}} className="seg-indicador-tootip" showCloseIcon={false}>
            {
                tooltip.tipo === tipoIndicador.gps ?
                    <TooltipSignal viaje={tooltip.viaje}/> :
                    <TooltipGenerico tooltip={tooltip}/>
            }
        </OverlayPanel>
        <IndicadoresClickOverlay/>
        {viajesEnSeguimiento.map((viaje) =>
            <SeguimientoViaje key={viaje.viajeId} viaje={viaje}/>
        )}
    </>)
}
const WithSeguimientoMonitoreoProvider = ({soloUnidades}) => (
    <ControlMapProvider>
        <SeguimientoMonitoreoProvider soloUnidades={soloUnidades} >
            <IndicadoresProvider>
                <SeguimientoMonitoreo soloUnidades={soloUnidades}/>
            </IndicadoresProvider>
        </SeguimientoMonitoreoProvider>
    </ControlMapProvider>
);

export default WithSeguimientoMonitoreoProvider
