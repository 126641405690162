import React, { useState, useEffect } from 'react';
import Form from "../../../../shared/forms/Form";
import Field from "../../../../shared/forms/Field";
import FormTextBox from "../../../../shared/forms/FormFields/FormTextBox";
import FormCalendar from "../../../../shared/forms/FormFields/FormCalendar";
import FormTime from "../../../../shared/forms/FormFields/FormTime";
import moment from "moment";
import FormDropDown from "../../../../shared/forms/FormFields/FormDropDown";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import useApiViajeSeguimientoMonitoreo from "../../../../api/operaciones/useApiSeguimientoMonitoreo";
const InformacionAlarma  = ({ viaje, enviarInfoAlPadre }) => {
    const {viajeSeleccionado} = useSeguimientoMonitoreo();
    const api = useApiViajeSeguimientoMonitoreo()
    const [info, setInfo] = useState({     
        codigoRuta: viaje.convenio != null ? viaje.convenio?.codigoRuta : viaje.exeditado?.codigoRuta ?? '',
        fechaPickup: moment(viaje.ruta.at(0)?.fechaSugerida, "YYYY-MM-DDTHH:mm:ss.SS").format("YYYY-MM-DD"),
        horaPickup: moment(viaje.ruta.at(0)?.fechaSugerida, "YYYY-MM-DDTHH:mm:ss.SS").format("HH:mm:ss"),
        carrier: 'ABCT',
        numeroEconomico: viaje.caja?.numeroEconomico,
        numeroEconomicoUnidad : viaje.unidad?.numeroEconomico,
        ubicacion :  viajeSeleccionado.ubicacion,
        razon :  '',
        detalles: '',
        fechaDescarga: moment(viaje.ruta[viaje.ruta.length - 1].fechaSugerida, "YYYY-MM-DDTHH:mm:ss.SS").format("YYYY-MM-DD"),
        horaDescarga: moment(viaje.ruta[viaje.ruta.length - 1].fechaSugerida, "YYYY-MM-DDTHH:mm:ss.SS").format("HH:mm:ss"),
        plan: '',
        distancia :  viaje.distancia,
        proveedor: viaje.destinoSegmento,
        planta : '',
        dock :'',
        nuevaEta: moment(viaje.ruta.filter(r => r.eta !== null).at(-1)?.eta ?? null, "YYYY-MM-DDTHH:mm:ss.SS").format("YYYY-MM-DD"),
        nuevaEtaHora: moment(viaje.ruta.filter(r => r.eta !== null).at(-1)?.eta ?? null, "YYYY-MM-DDTHH:mm:ss.SS").format("HH:mm:ss"),
        latitud: viaje.latitud,
        longitud : viaje.longitud,
        idViaje: viaje?.idViaje ?? '',
        idViajeList : viaje.idViajeList
    }); 
    console.log(info)
    useEffect(() => {
        setInfo(prevState => ({
          ...prevState,
          idViaje: viaje?.idViaje ?? '',
          numeroEconomico: viaje.caja?.numeroEconomico
        }));
      }, [viaje]); 
      const handleChange = (e) => {
        if (e.target) {    
        } else {        
          setInfo((prevState) => ({
            ...prevState,
            planta: e.planta,
            razon: e.razon,
            dock: e.dock,
            plan: e.plan,
            detalles: e.detalles,
            ubicacion: e.ubicacion,
            fechaPickup: e.fechaPickup,
            horaPickup: e.horaPickup,
            fechaDescarga: e.fechaDescarga,
            horaDescarga: e.horaDescarga,
            nuevaEta: e.nuevaEta,
            nuevaEtaHora: e.nuevaEtaHora,
            idViaje: e.idViaje
          }));
        }
      };
      
    useEffect(() => {
        enviarInfoAlPadre(info);
    }, [info, enviarInfoAlPadre]);
    const OpcionesDock = [
        { id: 1600, nombre: "XDOCK" },
        { id: 1601, nombre: "AAA" },
        { id: 1602, nombre: "RESS" },
    ];
    const plantas = [
        { id: 1603, nombre: "GM RAMOS" },
        { id: 1604, nombre: "RYDER RAMOS" },
    ];
    const obtenerInformacion  = async (id) => {  
       const response= await api.getViajeById(id,  {unidadId: viajeSeleccionado.unidadId, cajaId: viajeSeleccionado.cajaId});
       if (response) {
        setInfo({
          codigoRuta: response.convenio != null ? response.convenio?.codigoRuta : response.exeditado?.codigoRuta ?? '',
          idViaje: response?.load ?? '',
          fechaPickup: moment(response.ruta.at(0)?.fechaSugerida, "YYYY-MM-DDTHH:mm:ss.SS").format("YYYY-MM-DD"),
          horaPickup: moment(response.ruta.at(0)?.fechaSugerida, "YYYY-MM-DDTHH:mm:ss.SS").format("HH:mm:ss"),
          carrier: 'ABCT',
          numeroEconomico: response.caja?.numeroEconomico,
          ubicacion: viajeSeleccionado.ubicacion,
          razon: '',
          detalles: '',
          fechaDescarga: moment(response.ruta[response.ruta.length - 1].fechaSugerida, "YYYY-MM-DDTHH:mm:ss.SS").format("YYYY-MM-DD"),
          horaDescarga: moment(response.ruta[response.ruta.length - 1].fechaSugerida, "YYYY-MM-DDTHH:mm:ss.SS").format("HH:mm:ss"),
          plan: '',
          distancia: response.distancia,
          proveedor: response.destinoSegmento,
          planta: '',
          dock: '',
          nuevaEta: moment(response.ruta.filter(r => r.eta !== null).at(-1)?.eta ?? null, "YYYY-MM-DDTHH:mm:ss.SS").format("YYYY-MM-DD"),
          nuevaEtaHora: moment(response.ruta.filter(r => r.eta !== null).at(-1)?.eta ?? null, "YYYY-MM-DDTHH:mm:ss.SS").format("HH:mm:ss"),
          latitud: viaje.latitud,
          longitud : viaje.longitud
        });
      }
    };
    return (
        <div className={'seg-info-viaje'}>
        
            <span className={'form-value'} style={{alignSelf: 'center'}}>
                Alarma 3.5
            </span>
            <div className={'form-container'}>
            <Form columns={3}  values={info} onChange={handleChange}>
                <Field field={"codigoRuta"} label={"Nomenclatura de Ruta"} required={true} render={FormTextBox} cols={1} maxLength={255} disabled={true}/>
                <Field
                    name="idViaje"
                    label="Load ID"
                    required={true}
                    render={({ value, onChange }) => {
                      const currentValue =
                        viaje.loads.find((option) => option.idViaje === value) || viaje.loads[0];                   
                      return (
                        <FormDropDown
                          options={viaje.loads}
                          value={currentValue?.id}  
                          getOptionLabel={(option) => option.id}
                          onChange={(newValue) => {
                            obtenerInformacion(newValue);
                            onChange(newValue); 
                          }}
                        />
                      );
                    }}
                    cols={1}
                  />





                <Field label={'Fecha Recolección'} field={'fechaPickup'} render={FormCalendar} required={true} cols={1}/>
                <Field label={'Hora Recolección'} field={'horaPickup'} render={FormTime} required={true} cols={1}/>
                <Field label={'Fecha Descarga'} field={'fechaDescarga'} render={FormCalendar} required={true} cols={1}/>
                <Field label={'Hora Descarga'} field={'horaDescarga'} render={FormTime} required={true} cols={1}/>
                <Field label={'Nuevo ETA a Destino'} field={'nuevaEta'} render={FormCalendar} required={true} cols={1}/>
                <Field label={'Hora Nuevo ETA a Destino'} field={'nuevaEtaHora'} render={FormTime} required={true} cols={1}/>
                <Field field={"carrier"} label={"Carrier SCAC"} required={true} render={FormTextBox} cols={1} maxLength={255}  disabled={true}/>
                <Field field={"numeroEconomico"}  label={"Trailer#"} required={true} render={FormTextBox} cols={1} maxLength={255}  disabled={true}/>
                <Field field={"planta"} name ='plantas' label={"Número de Planta/Nombre"} required={true} render={FormDropDown} cols={1} options={plantas}  onChange={handleChange} disabled={false}/>
                <Field field={"dock"} name ='dock'  label={"Dock"} required={true} render={FormDropDown} cols={1} options={OpcionesDock} onChange={handleChange} disabled={false}/>          
                <Field field={"ubicacion"} label={"Ubicación Actual"} required={true} render={FormTextBox} cols={3} maxLength={255}  disabled={false}/>                   
                <Field field={"proveedor"} name ='proveedor'  label={"Proveedor/Ciudad Origen"} required={true} render={FormTextBox} cols={2} maxLength={255}  disabled={true}/>
                <Field field={"razon"} name ='razon' label={"Razón de Retazo"} required={true} render={FormDropDown} onChange={handleChange} cols={1} source={`incidenciasEdi/${viaje.clienteId}`} />
                <Field field={"detalles"}name ='detalles' label={"Detalles"} required={true} render={FormTextBox} onChange={handleChange} cols={1} maxLength={255}  disabled={false}/>
                <Field field={"plan"} label={"Plan de recuperacion"} required={true} render={FormTextBox} cols={1} maxLength={255}  onChange={handleChange} disabled={false}/>
                <Field field={"distancia"} label={"Distancia a destino"} required={true} render={FormTextBox} cols={1} maxLength={255}  disabled={true}/>
               
              
                
                {/* falta agregar link decuenta espejo */}

            </Form>
            </div>
          
        </div>
    )
}
export default InformacionAlarma;