/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";
import useApiDieselTanque from "../../../api/taller/useApiDieselTanque";
import FormClipboardImage from "../../../shared/forms/FormFields/FormClipboardImage";
import { useLoadingDispatch } from "../../../layout/show-loading-context";
import { tiposCombustible } from "../DieselConstantes";
import ConversionGalones from "./ConversionGalones";
import useSeguridad from "../../../Seguridad/useSeguridad";
import constantes from "../../../catalogos/constantes";

const Diesel = ({ value: diesel, parent, onChange, onValidate, formDisabled }) => {
    const api = useApiDieselTanque();
    const dispatch = useLoadingDispatch();    
    const seguridad = useSeguridad();
    let permisoEditarCargas= seguridad.tieneAcceso(constantes.opciones.editarCargas)
    const obtenerUltimoSello = async () => {
        if (parent.unidadId == null || parent.id > 0) return;

        const selloAnterior = await api.ultimoSello(parent.unidadId);
        onChange({ ...diesel, selloAnterior });
    };

    useEffect(() => {
        obtenerUltimoSello().then();
    }, [parent.unidadId]);

    const onLitrosBlur = async () => {
        if (diesel.litros == null || parent.esCargaExterna) {
            return;
        }

        const result = await api.obtenerPrecio({ ...diesel, combustibleId: tiposCombustible.diesel });

        if (!result.isSuccess) {
            dispatch({ type: "toast", message: result.message, severity: "warn" });
        }

        onChange({ ...diesel, precio: result.precio });
    };

    const handleChange = async (nuevoDiesel) => {
        onChange({
            ...nuevoDiesel,
            litros: nuevoDiesel.conversionGalones?.litros ?? nuevoDiesel.litros,
            precio: nuevoDiesel.conversionGalones?.precio ?? nuevoDiesel.precio,
        });
    };

    const isPrecioFieldDisabled = () => {
        return !parent.esCargaExterna || formDisabled || parent.convertirGalonesALitros;
    };

    return (
        <Form onChange={handleChange} columns={2} values={diesel} onValidate={onValidate} formDisabled={formDisabled}>
            {parent.convertirGalonesALitros && (
                <Field field={"conversionGalones"} render={(parent) => <ConversionGalones {...parent} />} cols={2} />
            )}

            <Field
                field={"litros"}
                label={"Litros"}
                required={true}
                mode="decimal"
                disabled={!permisoEditarCargas}
                maxFractionDigits={2}
                render={FormInputNumber}
                onBlur={onLitrosBlur}
            />

            <Field
                field={"precio"}
                label={"Precio Mxn"}
                required={true}
                disabled={isPrecioFieldDisabled()}
                mode="decimal"
                maxFractionDigits={4}
                render={FormInputNumber}
            />

            <Field field={"selloAnterior"} label={"Sello Anterior"} disabled={true} render={FormTextBox} />

            <Field
                field={"sello"}
                label={"Sello Nuevo"}
                disabled={parent.esCargaExterna || formDisabled}
                render={FormTextBox}
            />

            <Field
                field={"odometro"}
                label={"Odómetro"}
                required={true}
                render={FormInputNumber}
                mode="decimal"
                maxFractionDigits={2}
            />

            <span />

            {parent.esCargaExterna && <Field field={"ticket"} label={"Ticket"} required={true} render={FormTextBox} />}
            {parent.esCargaExterna && (
                <Field
                    field={"anexo"}
                    label="Anexo"
                    required={parent.controlador === "Sistema" ? false : true}
                    render={FormClipboardImage}
                />
            )}
            <Field field={"comentarios"} label="Comentarios" required={true} render={FormTextArea} rows={3} cols={2} />
        </Form>
    );
};

export default Diesel;
