/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FieldCalendar from "../../shared/controls/fieldCalendar";
import { Accordion, AccordionTab } from "primereact/accordion";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import InputFile from "../../shared/controls/inputFile";
import constantes from "../constantes";
const operador = ({ empleado, archivosAdicionales, documentosGenerales, documentosOperador,}) => {
    return (
     
        <>
                <Accordion multiple activeIndex={[0]}>
                    <AccordionTab
                        header={
                            <>
                                <span> Documentos Operador</span>
                            </>
                        }
                    >
                        {documentosOperador.map((item) => (
                            <>
                                {
                                    <div className="form-row">
                                        <h5 className="col-12">
                                            {item.tipoDocumento.requerido ? "*" : ""}
                                            {item.tipoDocumento.nombre}
                                        </h5>
                                        <FieldTextBox
                                            name="numeroDocumento"
                                            label={`Numero de ${item.tipoDocumento.nombre}`}
                                            colMd={5}
                                            value={item.numeroDocumento}
                                        />
                                        <FieldCalendar
                                            name="vigencia"
                                            label={`Vigencia ${item.tipoDocumento.nombre}`}
                                            colMd={5}
                                            value={item.vigencia}
                                            className="small large-padding-bottom"
                                            showButtonBar={false}
                                        />
                                        <div className={`form-group col-md-2 col-sm-6'`}>
                                            <label>&nbsp;</label>
                                            <div className="d-flex">
                                                <InputFile
                                                    name="archivo"
                                                    value={item.archivo}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        ))}
                        {empleado.licencias.map((item) => (
                            <>
                                {
                                    <div>
                                        <div className="form-row">
                                            <h5 className="col-12">*Licencia</h5>
                                            <FieldTextBox
                                                name="numeroDocumento"
                                                label={`Numero de Licencia`}
                                                colMd={5}
                                                value={item.numeroDocumento}
                                            />
                                            <FieldCalendar
                                                name="vigencia"
                                                label={`Vigencia Licencia`}
                                                colMd={5}
                                                value={item.vigencia}
                                                className="small large-padding-bottom"
                                                showButtonBar={false}
                                            />
                                            <div className={`form-group col-md-2 col-sm-6'`}>
                                                <label>&nbsp;</label>
                                                <div className="d-flex">
                                                    <InputFile
                                                        name="archivo"
                                                        value={item.archivo}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <FieldDropDown
                                                name="tipoLicencia"
                                                label="Categoria Licencia"
                                                colMd={5}
                                                source="tiposLicencia"
                                                filter
                                                value={item?.tipoLicencia}
                                            />

                                            <FieldDropDown
                                                name="categoriaLicencia"
                                                label="Modalidad Licencia"
                                                colMd={5}
                                                source="categoriasLicencia"
                                                filter
                                                value={item?.categoriaLicencia}
                                            />

                                            <FieldDropDown
                                                name="tipoLicenciaModalidad"
                                                label="Tipo Licencia"
                                                colMd={5}
                                                source="tiposLicenciaModalidad"
                                                filter
                                                value={item?.tipoLicenciaModalidad}
                                            />

                                            <div className={`form-group col-md-2 col-sm-6'`}>
                                                <label>&nbsp;</label>
                                                <div className="d-flex">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        ))}

                        <div className="form-row">
                        </div>
                    </AccordionTab>
                    <AccordionTab
                        header={
                            <>
                                <span> Documentos Generales</span>
                            </>
                        }
                    >
                        <table className="table table-borderless">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: "50%" }}>
                                    TIPO DOCUMENTO
                                </th>
                                <th scope="col" style={{ width: "30%" }}>
                                    VIGENCIA
                                </th>
                                <th scope="col" style={{ width: "20%" }}>
                                    DOCUMENTO
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {documentosGenerales.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="input">
                                            {item.tipoDocumento.requerido ? "*" : ""}
                                            {item.tipoDocumento.nombre}
                                        </td>
                                        <td>
                                            {item.tipoDocumento.id === constantes.tipoDocumento.antecedentes && (
                                                <FieldCalendar
                                                    name="vigencia"
                                                    value={item.vigencia}
                                                    className="small large-padding-bottom"
                                                    showButtonBar={false}
                                                />
                                            )}
                                        </td>
                                        <td className="input">
                                            <InputFile
                                                name="archivo"
                                                value={item.archivo}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </AccordionTab>
                    <AccordionTab
                        header={
                            <>
                                <span> Archivos Adicionales</span>
                            </>
                        }
                    >
                        <table className="table">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: "60%" }}>
                                    NOMBRE DEL DOCUMENTO
                                </th>
                                <th scope="col" style={{ width: "15%" }}></th>
                                <th scope="col" className="center" style={{ width: "15%" }}>
                                    ARCHIVO
                                </th>
                                <th className={"ag-grid header-icons"} style={{ width: "10%" }}>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {archivosAdicionales?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="input">
                                            <FieldTextBox
                                                name="nombre"
                                                value={item.nombre}
                                            />
                                        </td>
                                        <td className="input center">
                                            <InputFile
                                                name="archivo"
                                                value={item.archivo}
                                                disabled={true}
                                            />
                                        </td>
                                        <td className="input">
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </AccordionTab>
            </Accordion>

        </>
    )

}
export default operador;