import React from "react";
import {ReactComponent as AlarmaContrasteSvg} from './AlarmaContraste.svg';
import {ReactComponent as CamionContrasteSvg} from './CamionContraste.svg';
import {ReactComponent as OperadorSvg} from './Operador.svg';
import {ReactComponent as OperadorContrasteSvg} from './OperadorContraste.svg';
import {ReactComponent as PosicionSvg} from './Posicion.svg';
import {ReactComponent as PosicionContrasteSvg} from './PosicionContraste.svg';
import {ReactComponent as RutaSvg} from './Ruta.svg';
import {ReactComponent as RutaContrasteSvg} from './RutaContraste.svg';
import {ReactComponent as CamionSvg} from './Camion.svg';
import {ReactComponent as AlarmaSvg} from './Alarma.svg';
import {ReactComponent as Alarma35Svg} from './Alarma35.svg';
import {ReactComponent as Alarma35Contraste} from './Alarma35Contraste.svg';

const IconTabsIconos = ({iconName}) => {
    const icons = {
        AlarmaContraste:<AlarmaContrasteSvg/>,
        CamionContraste:<CamionContrasteSvg/>,
        Operador:<OperadorSvg/>,
        OperadorContraste:<OperadorContrasteSvg/>,
        Posicion:<PosicionSvg/>,
        PosicionContraste:<PosicionContrasteSvg/>,
        Ruta:<RutaSvg/>,
        RutaContraste:<RutaContrasteSvg/>,
        Camion:<CamionSvg/>,
        Alarma:<AlarmaSvg/>,
        Alarma35:<Alarma35Svg/>,
        Alarma35Contraste:<Alarma35Contraste/>,
    }

    return icons[iconName] ?
        <span>{icons[iconName]}</span>:
        <div style={{fontSize: 9}}>no icon</div>;
}
export default IconTabsIconos;