import {
    colorIndicadores,
    colorMap,
    indicadorColorMap,
    nombreColorIndicador, tipoIndicador, tiposIconoRuta
} from "./SeguimentoConstantes";
import constantes from "../../../administracion/constantes";
import moment from "moment";

export const getIcon = (item, viaje) => {
    // item.esCaja = true
    const path = item.esCaja ? "M4 0h9s4 0 4 4v14.6667s0 4 -4 4h-9s-4 0 -4 -4v-14.6667s0 -4 4 -4" : 'M17.4225 1.49461L29.0961 26.064L17.5327 20.9555L5.80922 26.0319L17.4225 1.49461Z'
    let anchor = null;
    if (window.google != null) {
        anchor = item.esCaja ? new window.google.maps.Point(9, 11) : new window.google.maps.Point(18, 16)
    }
    let color = "gray";
    if ((viaje?.viajeId > 0 || item.esCaja)) {
        color = colorMap.get(viaje?.color) ?? 'green';
    }


    return {
        path: path,
        fillColor: color,
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: 'black',
        rotation: item.orientacion,
        scale: 1,
        anchor
    }
};

const svgInicioIcon = `
  <svg xmlns="http://www.w3.org/2000/svg"
     width="512"
     height="512"
     x="0"
     y="0"
     viewBox="0 0 195 195"
     style="enable-background:new 0 0 512 512"
     xml:space="preserve"
     class="">
    <g>
    <path fill="#0081ff"
          d="M97.5 21.5c-33.3 0-60.3 27-60.3 60.3 0 8.6 1.8 16.7 5 24.1.1.3.3.6.4.9.3.7.7 1.5 1.1 2.2.2.3.3.6.5 1 .4.8.9 1.7 1.4 2.5.1.1.1.2.2.4 2.5 4.2 5.6 8.1 9 11.5l35.7 45.8c3.5 4.5 10.3 4.5 13.8 0l35.7-45.7c3.5-3.5 6.5-7.4 9.1-11.6 5.5-9.1 8.6-19.7 8.6-31 .1-33.4-26.9-60.4-60.2-60.4zm34.8 58.9c-.7 1.8-2 3.2-4 3.6-.8.2-1.7-.6-2.7-1 0 .3-.1.8-.1 1.3v27.9c0 2.8-1.2 4.1-4.1 4.1h-11.7c-2.6 0-3.8-1.2-3.8-3.9v-21H90.4c0 .5-.1 1-.1 1.5v19c0 3-1.2 4.3-4.3 4.3H75c-2.4 0-3.7-1.3-3.7-3.7V82.1c-1 .8-1.6 1.4-2.3 2-1.8 1.5-3.6 1.1-5.5-1.1-1.4-1.6-1.4-3.4.2-4.8 5.1-4.7 10.2-9.3 15.3-14 4.6-4.2 9.1-8.4 13.7-12.6.7-.6 1.4-1.3 2.1-1.9 1.8-1.6 3.8-1.6 5.7 0l9.9 8.7c6.8 5.9 13.5 11.8 20.3 17.8 1.2 1.1 2.3 2.4 1.6 4.2z"
          opacity="1"
          data-original="#d50000"
          class=""/>
</g>
</svg>
`;
const svgInicioUrl = "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svgInicioIcon);

const svgStopIcon =  `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve">
    <g>
    <path d="M256 0C153.755 0 70.573 83.182 70.573 185.426c0 126.888 165.939 313.167 173.004 321.035 6.636 7.391 18.222 7.378 24.846 0 7.065-7.868 173.004-194.147 173.004-321.035C441.425 83.182 358.244 0 256 0zm0 469.729c-55.847-66.338-152.035-197.217-152.035-284.301 0-83.834 68.202-152.036 152.035-152.036s152.035 68.202 152.035 152.035C408.034 272.515 311.861 403.37 256 469.729z" fill="#034da2" opacity="1" data-original="#000000"></path><path d="M256 92.134c-51.442 0-93.292 41.851-93.292 93.293S204.559 278.72 256 278.72s93.291-41.851 93.291-93.293S307.441 92.134 256 92.134zm0 153.194c-33.03 0-59.9-26.871-59.9-59.901s26.871-59.901 59.9-59.901 59.9 26.871 59.9 59.901-26.871 59.901-59.9 59.901z" fill="#034da2" opacity="1" data-original="#000000">
    </path>
    </g>
    </svg>
`
const svgStopUrl = "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svgStopIcon);


const svgVisitadoIcon =  `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M256 0C161.896 0 85.333 76.563 85.333 170.667c0 28.25 7.063 56.26 20.49 81.104L246.667 506.5c1.875 3.396 5.448 5.5 9.333 5.5s7.458-2.104 9.333-5.5l140.896-254.813c13.375-24.76 20.438-52.771 20.438-81.021C426.667 76.563 350.104 0 256 0zm0 256c-47.052 0-85.333-38.281-85.333-85.333S208.948 85.334 256 85.334s85.333 38.281 85.333 85.333S303.052 256 256 256z" fill="#034da2" opacity="1" data-original="#000000" class=""></path></g></svg>
`
const svgVisitadoUrl = "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svgVisitadoIcon);

const getIconUrl = (tipo) =>{
    switch (tipo){
        case tiposIconoRuta.inicio:
            return svgInicioUrl
        case tiposIconoRuta.enRuta:
            return svgStopUrl
        default:
            return svgVisitadoUrl
    }
}

export const getGeocercaIcono = (tipoIcono) => {

    return {
        url: getIconUrl(tipoIcono),
        scaledSize: new window.google.maps.Size(20, 20), // Ajusta el tamaño del ícono
        anchor: new window.google.maps.Point(20,20) // Centra el ancla
    }
};


export const getUnidades = (viaje) => {
    let result = []
    if (viaje.unidadId != null)
        result.push(viaje.unidadId)
    if (viaje.cajaId != null)
        result.push(viaje.cajaId)

    return result;
}


export const programado = (estatus) => {
    return estatus === constantes.estatusViajes.programado;
}


export const containerStyle = {
    height: '100%'
};

export const getColorIndicadores = (indicador) => {
    if (indicador?.color == null || indicador.color === 0)
        return 'gris'

    return colorIndicadores[indicador.color];
}

export const getContrast = (indicador) => {
    if (indicador == null)
        return ''

    return indicador?.color !== nombreColorIndicador.gris && indicador?.color !== nombreColorIndicador.amarillo && indicador?.color !== 0
        ? 'Contraste' : '';
}

export const getMapSliderRanges = () => {
    let horaActual = moment()
    let fechas = []
    let range = {
        0: '0'
    };
    // let now = moment().startOf('minute')
    for (let i = 0; i < 24; i++) {
        range[i] = `${horaActual.format('HH:mm')} hrs`;
        fechas[i] = horaActual.format('yyyy-MM-DD HH:mm:00');
        horaActual = horaActual.add(-1, 'hour');

    }
    horaActual = horaActual.add(-24, 'hour');
    range[24] = `${horaActual.format('ddd hh:mm')}`;
    fechas.push(horaActual.format('yyyy-MM-DD HH:mm:00'));

    horaActual = horaActual.add(-24, 'hour');
    range[25] = `${horaActual.format('ddd hh:mm')}`
    fechas.push(horaActual.format('yyyy-MM-DD HH:mm:00'));

    return {range, fechas};
}

export const actualizarColorViaje = (viaje) => {

    viaje.color = nombreColorIndicador.verde;
    if (viaje.indicadores == null)
        return viaje

    for (let indicadorId in viaje.indicadores) {
        indicadorId = parseInt(indicadorId)
        const indicador = viaje.indicadores[indicadorId];
        if (indicador.color === nombreColorIndicador.verde || indicador.color === nombreColorIndicador.gris || indicador.color === 0) {
            continue;
        }

        const colorOFuncion = indicadorColorMap.get(indicadorId);

        const color = typeof colorOFuncion === 'function' ?
            colorOFuncion(viaje, viaje.indicadores[indicadorId]) :
            colorOFuncion;

        if (color > viaje.color) {
            viaje.color = color;
        }

        if (color === nombreColorIndicador.rojo) {
            break;
        }
    }

    return viaje;
}


/**
 * Determines the priority color of a trip based on its indicators.
 *
 * This function evaluates the trip object and checks if its indicators
 * contain a specific type (`botonPanico`). If no indicators are present,
 * it defaults to returning the trip's color. If the specific indicator exists,
 * it checks its color. If the indicator's color matches the defined "red" color,
 * the priority value of `100` is returned; otherwise, the trip's color is returned.
 *
 * @param {Object} viaje - The trip object to evaluate.
 * @param {Object} viaje.indicadores - Optional indicators associated with the trip.
 * @param {string} viaje.color - Default color associated with the trip.
 * @returns {number|string} - Returns the priority value `100` in case of a red indicator,
 * otherwise, returns the trip's default color.
 */
export const prioiridadViaje = (viaje)=>{
    if (viaje?.indicadores == null)
        return viaje.color
    const indicador = viaje.indicadores[tipoIndicador.botonPanico]
    return indicador?.color === nombreColorIndicador.rojo ?
        100 : viaje.color
}

export const libraries = ["drawing"];