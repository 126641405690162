import {
    Routes,
    Route, useLocation
} from 'react-router-dom'
import Home from "./Home";
import {useAuth} from "../Seguridad/AuthProvider";
import {
    Navigate,
    Outlet
} from 'react-router-dom'
import AuthCallback from "../Seguridad/AuthCallback";
import Comercial from "../operaciones/comercial/comercial";
import DetalleCotizacion from "../operaciones/comercial/cotizaciones/detalleCotizacion";
import DetalleConvenio from "../operaciones/comercial/convenios/detalleConvenio";
import DetalleExpeditado from "../operaciones/comercial/expeditado/detalleExpeditado";
import Empleados from "../catalogos/empleados/empleados";
import GestionDeFlotillas from "../operaciones/flotillas/gestionDeFlotillas";
import Mantenimiento from "../taller/mantenimiento/mantenimiento";
import DetalleOrdenServicio from "../taller/mantenimiento/ordentrabajo/detalleOrdenTrabajo";
import Llanta from "../taller/llanta/llanta";
import Diesel from "../taller/diesel/diesel";
import ControlPatios from "../operaciones/ControlPatios/controlPatios";
import ControlPatioDetalle from "../operaciones/ControlPatios/controlPatioDetalle";
import Perfiles from "../catalogos/Perfiles/Perfiles";
import Usuarios from "../catalogos/Usuarios/Usuarios";
import ProgramacionViajes from "../operaciones/viajes/programacion/programacionViaje";
import FreightVerify from "../operaciones/viajes/freightVerify/freightVerify";
import Parametro from "../catalogos/Parametros/Parametros";
import CheckListDetalle from "../operaciones/ControlPatios/checkListUnidades/checklistDetalle";
import SeguimientoMonitoreo from "../operaciones/viajes/seguimientoMonitoreo/seguimientoMonitoreo";
import Clientes from "../catalogos/clientes/clientes";
import Prefacturacion from "../operaciones/prefacturacionPrincipal/programacion/prefacturacion";
import LiberacionViaje from "../administracion/liberacion/liberacionViaje";
import MovimientoSecciones from "../administracion/movimientos/movimientoSecciones";
import AjusteDiesel from "../administracion/ajustediesel/ajusteDiesel";
import TipoDeCambio from "../administracion/tipodecambio/tipodecambio";
import VacacionesSecciones from "../administracion/vacaciones/vacacionesSecciones";
import LiquidacionSecciones from "../administracion/liquidaciones/liquidacionSecciones";
import LiquidacionReportesSecciones from "../administracion/liquidaciones/liquidacionReportesSecciones";
import Direcciones from "../catalogos/Direcciones/direcciones";
import EstatusDiarios from "../reportes/estatusDiarios/EstatusDiarios";
import CajaChica from "../reportes/cajaChica/CajaChica";
import ConceptosCobro from "../catalogos/ConceptosCobro/ConceptosCobro";
import MantenimientoStp from '../catalogos/MantenimientoStp/MantenimientoStp';
import ConfiguracionCajaChica from "../catalogos/CajaChica/ConfiguracionCajaChica";
import Empresas from "../catalogos/Empresas/Empresas";
import Proveedores from "../catalogos/Diesel/Proveedores";
import Geocercas from "../catalogos/Geocercas/Geocercas";
import MarcaLlanta from "../catalogos/MarcasLlantas/MarcaLlanta";
import OrderToCash from "../operaciones/orderToCash/OrderToCash";
import ConfiguracionBono from "../catalogos/Bonos/ConfiguracionBono";
import Descuentos from "../catalogos/Descuentos/Descuentos";
import Bonosrh from "../catalogos/BonosRh/BonosRh";
import FacturacionPorUnidad from "../reportes/VentasPorUnidad/FacturacionPorUnidad";
import ClientesDocumentos from "../catalogos/ClientesDocumentos/clientesDocumentos";
import ClientesEspecificaciones from "../catalogos/ClientesEspecificaciones/ClientesEspecificaciones";
import Puestos from "../catalogos/Puestos/puestos";
import Equipos from "../catalogos/Equipos/Equipos";
import MarcasEquipos from "../catalogos/MarcasEquipos/MarcasEquipos";
import ModelosEquipos from "../catalogos/ModelosEquipos/ModelosEquipos";
import ConfiguracionLear from "../operaciones/configuracionLear/ConfiguracionLear";
import Agenda from "../catalogos/Agenda/Agenda";
import FacturacionMultiempresa from "../operaciones/viajes/facturacion/facturacionMultiempresa";
import LoginError from "../Seguridad/LoginError";
import LoginAzure from "../Seguridad/LoginAzure";
import Incidencia from "../administracion/incidencias/incidencia";
import Cajaahorro from "../administracion/cajaahorro/cajaahorro";
import PrestamoCajaAhorro from "../administracion/prestamocajaahorro/prestamoCajaAhorro";
import Estaciones from "../catalogos/Estaciones/Estaciones";
import EstatusDiarioGrafica from "../reportes/estatusDiarios/EstatusDiarioGrafica";
import SeguimientoQS3 from "../operaciones/viajes/seguimientoQS3/seguimientoQS3";
import WithSeguimientoMonitoreoProvider
    from "../operaciones/viajes/SeguimientoyMonitoreo/WithSeguimientoMonitoreoProvider";
import SeguimientoMultipleViajes from "../operaciones/viajes/SeguimientoyMonitoreo/mapas/SeguimientoMultipleViajes";
import Unauthorized from "./Unauthorized";
import RetiroCajaAhorro from '../administracion/retirocajaahorro/retiroCajaAhorro';
import Incapacidad from '../administracion/incapacidad/incapacidad';
import InventariosCaja from "../taller/inventariosCaja/InventariosCaja";
import GastoSeguridad from '../operaciones/viajes/gastoSeguridad/gastoSeguridad';
import Capacitaciones from '../administracion/capacitaciones/capacitaciones';
const GuardRoute = () => {
    const auth = useAuth()
    return (auth?.isAuthenticated() || auth?.loggedOut) ? <Outlet/> : <Navigate to="/login"/>
}


const AppRoutes = () => {
    return (
        <Routes>
            <Route exact={true} element={<GuardRoute/>}>
                <Route path="/" exact={true} element={<Home/>}/>
                <Route path="/home" exact={true} element={<Home/>}/>
                <Route path="/comercial" exact={true} element={<RequireAuth><Comercial/></RequireAuth>}/>
                <Route path="/comercial/nueva_cotizacion/:clonar?/:id?" exact={true}
                       element={<DetalleCotizacion/>}/>
                <Route path="/comercial/editar_cotizacion/:id" exact={true}
                       element={<DetalleCotizacion/>}/>
                <Route path="/comercial/editar_convenio/:id" exact={true}
                       element={<DetalleConvenio/>}/>
                <Route path="/comercial/nuevo_expeditado/:id?" exact={true}
                       element={<DetalleExpeditado/>}/>
                <Route path="/comercial/editar_expeditado/:id" exact={true}
                       element={<DetalleExpeditado/>}/>
                <Route path="/comercial/administracion_de_personal" exact={true}
                       element={<RequireAuth><Empleados/></RequireAuth>}/>
                <Route path="/comercial/gestion_de_flotillas" exact={true}
                       element={<RequireAuth><GestionDeFlotillas/></RequireAuth>}/>
                <Route path="/catalogos/nuevo_empleado" exact={true}
                       element={<DetalleExpeditado/>}/>
                <Route path="/catalogos/editar_empleado/:id" exact={true}
                       element={<DetalleExpeditado/>}/>
                <Route path="/taller/mantenimiento" exact={true} element={<RequireAuth><Mantenimiento/></RequireAuth>}/>
                <Route path="/taller/mantenimiento/nuevo_orden/:id?" exact={true}
                       element={<DetalleOrdenServicio/>}/>
                <Route path="/taller/mantenimiento/editar_orden/:id" exact={true}
                       element={<DetalleOrdenServicio/>}/>
                <Route path="/taller/llantas" exact={true} element={<RequireAuth><Llanta/></RequireAuth>}/>
                <Route path="/taller/diesel" exact={true} element={<RequireAuth><Diesel/></RequireAuth>}/>
                <Route path="//taller/inventariocajas" exact={true} element={<RequireAuth><InventariosCaja/></RequireAuth>}/>
                <Route path="//viajes/gastoSeguridad" exact={true} element={<RequireAuth><GastoSeguridad/></RequireAuth>}/>
                <Route path="/controlDePatios" exact={true} element={<RequireAuth><ControlPatios/></RequireAuth>}/>
                <Route path="/controlDePatios/nuevo/:id" exact={true}
                       element={<ControlPatioDetalle/>}/>
                <Route path="/controlDePatios/editar/:salida/:id" exact={true}
                       element={<ControlPatioDetalle/>}/>
                <Route path="/controlDePatios/salida/:salida/:id" exact={true}
                       element={<ControlPatioDetalle/>}/>
                <Route path="/catalogo/perfiles" exact={true} element={<RequireAuth><Perfiles/></RequireAuth>}/>
                <Route path="/catalogo/usuarios" exact={true} element={<RequireAuth><Usuarios/></RequireAuth>}/>
                <Route path="/programacionViajes" exact={true}
                       element={<RequireAuth><ProgramacionViajes/></RequireAuth>}/>               
                <Route path="/freightVerify" exact={true} element={<RequireAuth><FreightVerify/></RequireAuth>}/>
                <Route path="/catalogo/parametros" exact={true} element={<RequireAuth><Parametro/></RequireAuth>}/>
                <Route path="/checklist/nuevo/:id/:esTemporal" exact={true}
                       element={<CheckListDetalle/>}/>
                <Route path="/checklist/editar/:id" exact={true}
                       element={<CheckListDetalle/>}/>
                <Route path="/seguimiento_y_monitoreo_v2" exact={true}
                       element={<RequireAuth><WithSeguimientoMonitoreoProvider/></RequireAuth>}/>
                <Route path="/monitoreo_seguridad" exact={true}
                       element={<RequireAuth><WithSeguimientoMonitoreoProvider soloUnidades={true} /></RequireAuth>}/>
                <Route path="/seguimiento_y_monitoreo_viajes" exact={true}
                       element={<RequireAuth><SeguimientoMultipleViajes/></RequireAuth>}/>
                <Route path="/seguimiento" exact={true} element={<RequireAuth><SeguimientoMonitoreo/></RequireAuth>}/>
                <Route path="/catalogo/clientes" exact={true} element={<RequireAuth><Clientes/></RequireAuth>}/>
                <Route path="/prefacturacion" exact={true} element={<RequireAuth><Prefacturacion/></RequireAuth>}/>
                <Route path="/liberacion_de_viajes" exact={true}
                       element={<RequireAuth><LiberacionViaje/></RequireAuth>}/>
                <Route path="/movimientos" exact={true} element={<RequireAuth><MovimientoSecciones/></RequireAuth>}/>
                <Route path="/ajuste_diesel" exact={true} element={<RequireAuth><AjusteDiesel/></RequireAuth>}/>
                <Route path="/tipodecambio" exact={true} element={<RequireAuth><TipoDeCambio/></RequireAuth>}/>
                <Route path="/vacaciones" exact={true} element={<RequireAuth><VacacionesSecciones/></RequireAuth>}/>
                <Route path="/liquidacion" exact={true} element={<RequireAuth><LiquidacionSecciones/></RequireAuth>}/>
                <Route path="/reporte_liquidaciones" exact={true}
                       element={<RequireAuth><LiquidacionReportesSecciones/></RequireAuth>}/>
                <Route path="/catalogo/direcciones" exact={true} element={<RequireAuth><Direcciones/></RequireAuth>}/>
                <Route path="/reportes/estatus_diario" exact={true}
                       element={<RequireAuth><EstatusDiarios/></RequireAuth>}/>
                <Route path="/reportes/caja_chica" exact={true} element={<RequireAuth><CajaChica/></RequireAuth>}/>
                <Route path="/catalogo/conceptosCobro" exact={true}
                       element={<RequireAuth><ConceptosCobro/></RequireAuth>}/>
                <Route path="/catalogo/configuracion_caja_chica" exact={true}
                       element={<RequireAuth><ConfiguracionCajaChica/></RequireAuth>}/>
                <Route path="/catalogo/empresas" exact={true} element={<RequireAuth><Empresas/></RequireAuth>}/>
                <Route path="/catalogo/proveedores_diesel" exact={true}
                       element={<RequireAuth><Proveedores/></RequireAuth>}/>
                <Route path="/catalogo/geocercas" exact={true} element={<RequireAuth><Geocercas/></RequireAuth>}/>
                <Route path="/catalogo/marcallanta" exact={true} element={<RequireAuth><MarcaLlanta/></RequireAuth>}/>
                <Route path="/OrderToCash" exact={true} element={<RequireAuth><OrderToCash/></RequireAuth>}/>
                <Route path="/catalogo/configuracion_bono" exact={true}
                       element={<RequireAuth><ConfiguracionBono/></RequireAuth>}/>
                <Route path="/catalogo/descuentos" exact={true} element={<RequireAuth><Descuentos/></RequireAuth>}/>
                <Route path="/catalogo/BonosRh" exact={true} element={<RequireAuth><Bonosrh/></RequireAuth>}/>
                <Route path="/reportes/facturacion_por_unidad" exact={true}
                       element={<RequireAuth><FacturacionPorUnidad/></RequireAuth>}/>
                <Route path="/catalogo/ClientesDocumentos" exact={true}
                       element={<RequireAuth><ClientesDocumentos/></RequireAuth>}/>
                <Route path="/catalogo/ClientesEspecificaciones" exact={true}
                       element={<RequireAuth><ClientesEspecificaciones/></RequireAuth>}/>
                <Route path="/catalogo/puestos" exact={true} element={<RequireAuth><Puestos/></RequireAuth>}/>
                <Route path="/catalogo/Equipos" exact={true} element={<RequireAuth><Equipos/></RequireAuth>}/>
                <Route path="/catalogo/marcasEquipos" exact={true}
                       element={<RequireAuth><MarcasEquipos/></RequireAuth>}/>
                <Route path="/catalogo/modelosEquipos" exact={true}
                       element={<RequireAuth><ModelosEquipos/></RequireAuth>}/>
                <Route path="/configuracionLear" exact={true}
                       element={<RequireAuth><ConfiguracionLear/></RequireAuth>}/>
                <Route path="/catalogo/agenda" exact={true} element={<RequireAuth><Agenda/></RequireAuth>}/>
                <Route path="/facturacion_multiempresas" exact={true}
                       element={<RequireAuth><FacturacionMultiempresa/></RequireAuth>}/>
                <Route path="/incidencia" exact={true} element={<RequireAuth><Incidencia/></RequireAuth>}/>
                <Route path="/capacitaciones" exact={true} element={<RequireAuth><Capacitaciones/></RequireAuth>}/>
                <Route path="/caja_ahorro" exact={true} element={<RequireAuth><Cajaahorro/></RequireAuth>}/>
                <Route path="/prestamo_Caja_Ahorro" exact={true}
                       element={<RequireAuth><PrestamoCajaAhorro/></RequireAuth>}/>
               <Route path="/retiro_ahorro" exact={true} element={<RequireAuth><RetiroCajaAhorro/></RequireAuth>}/>
                <Route path="/catalogo/estaciones" exact={true} element={<RequireAuth><Estaciones/></RequireAuth>}/>
                <Route path="/reportes/estatus_diario-grafica" exact={true}
                       element={<RequireAuth><EstatusDiarioGrafica/></RequireAuth>}/>
                <Route path="/seguimientoQS3" exact={true} element={<RequireAuth><SeguimientoQS3/></RequireAuth>}/>
                <Route path="/catalogo/mantenimientoStp" exact={true}
                       element={<RequireAuth><MantenimientoStp/></RequireAuth>}/>
                <Route path="/incapacidad" exact={true} element={<RequireAuth><Incapacidad/></RequireAuth>}/>
            </Route>
            <Route path="/login" exact={true} element={<LoginAzure/>}/>
            <Route path="/errorLogin" exact={true} element={<LoginError/>}/>
            <Route path="/auth/callback" exact={true} element={<AuthCallback/>}/>
            <Route path="/accesoDenegado" exact={true} element={<Unauthorized/>}/>
        </Routes>
    )
}

function RequireAuth({children}) {
    const auth = useAuth()
    let location = useLocation();
    if (auth === null) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    } else {
        let existe = true;
        auth.menus.forEach((menu) => {
            if (!existe)
                existe = menu.menus.some(m => m.ruta === location.pathname);
        });

        if (!existe)
            return <Navigate to="/accesoDenegado" state={{from: location}} replace/>;
    }

    return children;
}


export default AppRoutes
