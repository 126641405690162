import {colorIndicadores, nombreColorIndicador} from "../SeguimentoConstantes";

export const indicadoresVelocidad = [
    {colorId: nombreColorIndicador.gris, color: colorIndicadores[nombreColorIndicador.gris], tooltip: '0 km/h'},
    {colorId: nombreColorIndicador.verde, color: colorIndicadores[nombreColorIndicador.verde], tooltip: '0 a 85 km/h'},
    {
        colorId: nombreColorIndicador.amarillo,
        color: colorIndicadores[nombreColorIndicador.amarillo],
        tooltip: '85 a 95 km/h'
    },
    {colorId: nombreColorIndicador.rojo, color: colorIndicadores[nombreColorIndicador.rojo], tooltip: 'mas de 95 km/h'},
]

export const indicadoresMotor = [
    {colorId: nombreColorIndicador.gris, color: colorIndicadores[nombreColorIndicador.gris], tooltip: 'Motor Apagado'},
    {
        colorId: nombreColorIndicador.verde,
        color: colorIndicadores[nombreColorIndicador.verde],
        tooltip: 'menos de 10 min. detenido'
    },
    {
        colorId: nombreColorIndicador.amarillo,
        color: colorIndicadores[nombreColorIndicador.amarillo],
        tooltip: '10 a 30 min. detenido'
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: 'mas de 30 min. detenido'
    },
]

export const indicadoresGps = [
    {
        colorId: nombreColorIndicador.verde,
        color: colorIndicadores[nombreColorIndicador.verde],
        tooltip: 'Unidad y Caja Posicionando'
    },
    {
        colorId: nombreColorIndicador.rosa,
        color: colorIndicadores[nombreColorIndicador.rosa],
        tooltip: 'Caja con mas de 10 min. sin posicionar'
    },
    {
        colorId: nombreColorIndicador.azul,
        color: colorIndicadores[nombreColorIndicador.azul],
        tooltip: 'Unidad con mas de 10 min. sin posicionar'
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: 'Unidad y caja con mas de 10 min. sin posicionar'
    },
]

export const indicadoresLastPosition = [
    {
        colorId: nombreColorIndicador.gris,
        color: colorIndicadores[nombreColorIndicador.gris],
        tooltip: 'Sin Eta Calculado'
    },
    {colorId: nombreColorIndicador.verde, color: colorIndicadores[nombreColorIndicador.verde], tooltip: 'En tiempo'},
    {
        colorId: nombreColorIndicador.amarillo,
        color: colorIndicadores[nombreColorIndicador.amarillo],
        tooltip: '0 a 1 hora (30 min si es expeditado) tarde'
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: 'mas de 1 hora (30 min si es expeditado) tarde'
    },
]

export const indicadoresDiesel = [
    {
        colorId: nombreColorIndicador.gris,
        color: colorIndicadores[nombreColorIndicador.gris],
        tooltip: 'Sin extracción detectada'
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: 'Con extracción diesel detectada'
    },
]

export const indicadoresGeocercaProhibida = [
    {
        colorId: nombreColorIndicador.verde,
        color: colorIndicadores[nombreColorIndicador.gris],
        tooltip: 'Fuera de geocerca prohibida'
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: 'Dentro de geocerca prohibda'
    },
]

export const indicadoresBrecha = [
    {
        colorId: nombreColorIndicador.gris,
        color: colorIndicadores[nombreColorIndicador.gris],
        tooltip: 'Sin unidad o caja'
    },
    {
        colorId: nombreColorIndicador.verde,
        color: colorIndicadores[nombreColorIndicador.verde],
        tooltip: 'Sin separación'
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: 'Con mas de 3 kms de separación'
    },
]

export const indicadoresUnidadSinViaje = [
    {
        colorId: nombreColorIndicador.gris,
        color: colorIndicadores[nombreColorIndicador.gris],
        tooltip: 'Sin movimiento o con viaje'
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: 'Unidad moviendose sin viaje'
    },
]


export const horasDeManejo = [
    {
        colorId: nombreColorIndicador.gris,
        color: colorIndicadores[nombreColorIndicador.gris],
        tooltip: 'Sin unidad o viaje sin iniciar'
    },
    {
        colorId: nombreColorIndicador.verde,
        color: colorIndicadores[nombreColorIndicador.verde],
        tooltip: '8 o mas horas de descanso en las últimas 24'
    },
    {
        colorId: nombreColorIndicador.amarillo,
        color: colorIndicadores[nombreColorIndicador.amarillo],
        tooltip: 'Entre 4 y 7 Horas de descanso'
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: 'Mas de 4 horas de descanso'
    },
]

export const botonPanico = [
    {
        colorId: nombreColorIndicador.gris,
        color: colorIndicadores[nombreColorIndicador.gris],
        tooltip: 'Sin botón de pánico detectado'
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: 'Botón de pánico presionado!'
    },
]

export const contadorTotales = [
    {
        colorId: nombreColorIndicador.verde,
        color: colorIndicadores[nombreColorIndicador.verde],
        tooltip: ''
    },
    {
        colorId: nombreColorIndicador.amarillo,
        color: colorIndicadores[nombreColorIndicador.amarillo],
        tooltip: ''
    },
    {
        colorId: nombreColorIndicador.rojo,
        color: colorIndicadores[nombreColorIndicador.rojo],
        tooltip: ''
    },
]