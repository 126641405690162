import React from "react";
import constantes from "../../../catalogos/constantes";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import produce from "immer";
import constantesOperacion from "../../constantes";


const RutasViaje = ({viaje, errors, actualizar, soloLectura = false}) => {
    const esViajeVacio = viaje.tipoViajeId === constantesOperacion.tipoProgramacionViaje.vacio;
    const rutas = viaje?.rutas;
    const actualizarRuta = (value, propertyName, index) => {
        const newValue = produce(rutas, (draft) => {
            if (propertyName === "direccion" && value != null) {
                draft[index].planta = value.nombre;
                draft[index].direccionCompleta = value.direccionCompleta;
            }

            if (propertyName === "esOrigenDestino") {
                if (draft[index].nombre.includes('ORIGEN') || draft[index].nombre.includes('ORIGEN RETORNO')) {
                    draft.filter(d => d.nombre.includes('ORIGEN') || draft[index].nombre.includes('ORIGEN RETORNO')).forEach((item) => {
                        item.esOrigenDestino = false;
                    });
                }
                if (draft[index].nombre.includes('DESTINO') || draft[index].nombre.includes('STOP') || draft[index].nombre === 'RETORNO') {
                    draft.filter(d => d.nombre.includes('DESTINO') || d.nombre.includes('STOP') || d.nombre === 'RETORNO').forEach((item) => {
                        item.esOrigenDestino = false;
                    });
                }
            }

            draft[index][propertyName] = value;
        });

        actualizar(newValue, "rutas");
    };

    const cargaDescargaInvalido = (index) => {
        return errors[`rutas[${index}]`]?.esCarga != null && !rutas[index].esCarga && !rutas[index].esDescarga
            && !rutas[index].desenganchar;
    };

    const deshabilitado = (ruta) => {
        return !viaje.rutasEditables || (!esViajeVacio && (viaje.tramoEnRuta >= ruta.tramo
            && ruta.stopNumber !== constantes.tipoRuta.destino && ruta.stopNumber !== constantes.tipoRuta.retorno));
    };

    const fechaDeshabilitada = (ruta) => {
        return deshabilitado(ruta) || (viaje.padreIdViajeVacio != null && !ruta.esOrigen)
    };

    const autocompleteDeshabilitado = (ruta) => {
        return deshabilitado(ruta) || viaje.padreIdViajeVacio != null;
    };

    const mostrarInicioSegmento = (ruta) => {
        return viaje.esSegmentado && ruta.stopNumber !== 0 && ruta.stopNumber < 100 && !fechaDeshabilitada(ruta);
    };

    const quitarInicioSegmento = (index) => {
        const newValue = produce(rutas, (draft) => {
            draft[index].fechaInicioSegmento = null;
            draft[index].horaInicioSegmento = "";
            draft[index].mostrarInicioSegmento = false;
        });

        actualizar(newValue, "rutas");
    };

    return (
        <table className="table table-sm">
            <thead className="thead-light">
            <tr>
                <th/>
                <th/>
                <th>DIRECCIÓN</th>

                {!esViajeVacio && <th/>}

                <th colSpan={2} className="text-center" style={{width: '40%'}}>FECHA Y HORA</th>
            </tr>
            </thead>
            <tbody>
            {rutas?.map((item, index) => {
                return (
                    <tr key={item.id}>
                        <td>
                            <FieldCheckBox name="esOrigenDestino"
                                           value={item.esOrigenDestino} errors={errors[`rutas[${index}]`]}
                                           onChange={(value, name) => actualizarRuta(value, name, index)}
                                           disabled={soloLectura}/>
                        </td>
                        <td>
                            <b>{item.nombre}</b>
                            {index + 1 < rutas.length &&
                                <div className="pt-2"><i className="pi pi-ellipsis-v"/></div>
                            }
                        </td>
                        <td>
                            {esViajeVacio || item.esNuevoTramo ?
                                <>
                                    <FieldAutoComplete name="direccion"
                                                       errors={errors[`rutas[${index}]`]}
                                                       source={`direcciones/${constantes.modulos.paradas}`}
                                                       value={item.direccion}
                                                       disabled={autocompleteDeshabilitado(item) || soloLectura}
                                                       onChange={(value, name) => actualizarRuta(value, name, index)}/>
                                    <div style={{fontSize: '0.8em'}}>{item.direccionCompleta}</div>
                                </>
                                :
                                <>
                                    <div><b>{item.planta}</b></div>
                                    <div style={{fontSize: '0.8em'}}>{item.direccionCompleta}</div>
                                </>
                            }
                        </td>

                        {!esViajeVacio &&
                            <td>
                                <div className={`${cargaDescargaInvalido(index) ? 'error' : ''}`}>
                                    <FieldCheckBox name="esCarga" label="Carga" value={item.esCarga}
                                                   onChange={(value, name) => actualizarRuta(value, name, index)}
                                                   disabled={deshabilitado(item) || soloLectura} index={index}/>
                                    <FieldCheckBox name="desenganchar" label="Desenganchar" value={item.desenganchar}
                                                   onChange={(value, name) => actualizarRuta(value, name, index)}
                                                   disabled={deshabilitado(item) || soloLectura} index={index}/>
                                    <FieldCheckBox name="esDescarga" label="Descarga"
                                                   value={item.esDescarga}
                                                   onChange={(value, name) => actualizarRuta(value, name, index)}
                                                   disabled={deshabilitado(item) || soloLectura} index={index}/>
                                </div>
                            </td>
                        }

                        <td>
                            <div className="d-flex flex-column">
                                <div>
                                    <FieldCalendar name="fecha" className="xs" value={item.fecha}
                                                   showButtonBar={false} errors={errors[`rutas[${index}]`]}
                                                   onChange={(value, name) => actualizarRuta(value, name, index)}
                                                   disabled={fechaDeshabilitada(item) || soloLectura} />
                                </div>
                                {mostrarInicioSegmento(item) &&
                                    <>
                                        {item.mostrarInicioSegmento ?
                                            <div className="mt-1">
                                                <FieldCalendar name="fechaInicioSegmento" className="xs"
                                                               value={item.fechaInicioSegmento}
                                                               showButtonBar={false} errors={errors[`rutas[${index}]`]}
                                                               onChange={(value, name) => actualizarRuta(value, name, index)}
                                                               disabled={fechaDeshabilitada(item) || soloLectura}/>
                                            </div>
                                            : <span className="cursor-pointer text-primary mt-1"
                                                    onClick={() => actualizarRuta(true, 'mostrarInicioSegmento', index)}>
                                                    Cambiar Inicio </span>
                                        }
                                    </>
                                }
                            </div>
                        </td>

                        <td>
                            <div className="d-flex flex-column">
                                <div>
                                    <FieldTextBox type="time" name="hora" value={item.hora}
                                                  errors={errors[`rutas[${index}]`]}
                                                  onChange={(value, name) => actualizarRuta(value, name, index)}
                                                  disabled={fechaDeshabilitada(item) || soloLectura}/>
                                </div>

                                {mostrarInicioSegmento(item) && item.mostrarInicioSegmento &&
                                    <div className="mt-1 position-relative">
                                        <FieldTextBox type="time" name="horaInicioSegmento"
                                                      value={item.horaInicioSegmento}
                                                      errors={errors[`rutas[${index}]`]}
                                                      onChange={(value, name) => actualizarRuta(value, name, index)}
                                                      disabled={fechaDeshabilitada(item) || soloLectura}/>
                                        <i className="position-absolute text-danger cursor-pointer pi pi-times"
                                           style={{top: 0, right: 0}} onClick={() => quitarInicioSegmento(index)}/>
                                    </div>
                                }
                            </div>

                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    );
};


export default RutasViaje;
