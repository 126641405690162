import useServiceFactory from "../serviceFactory";
import constantes from "../../catalogos/constantes";
const apiUrl = `/operaciones/flotillas`;

const useApiFlotillas = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    function actualizarEsMotriz(unidad) {
        if (unidad.datosGenerales != null) {
            unidad.datosGenerales.esMotriz = unidad.datosGenerales.tipoEquipo?.id === constantes.tiposEquipo.motriz;
        }
        return unidad;
    }

    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => {
            let result =  await service.get(`${apiUrl}/${id}`, {showLoading:true});
            return actualizarEsMotriz(result);
        },
        guardar: async (params) => {
            let result = await service.post(`${apiUrl}`, params);
            return actualizarEsMotriz(result);
        },
        actualizar: async (id,params) => {
            let result = await service.put(`${apiUrl}/${id}`, params);
            return actualizarEsMotriz(result);
        },
        alta: async (id) => service.patch(`${apiUrl}/${id}/alta`, {}),
        baja: async (id,params) => service.put(`${apiUrl}/${id}/baja`, params),
        subirArchivos: async(id, formData) => service.post(`${apiUrl}/${id}/archivos`, formData),
        subirArhivosAccesorios: async(id, formData) => service.post(`${apiUrl}/${id}/archivosAccesorios`, formData),
        coordenadas:async (params) => service.get(`${apiUrl}/coordenadas`, {params}, {showLoading: false}),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `Flotilla.xlsx`
            }),
        excelIncidencia: async (params) => service.get(`${apiUrl}/excelIncidencias`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `FlotillaIncidencias.xlsx`
        }),
        subirPermisoTransitoLocal: async(tipoDocumento,formData) => service.post(`${apiUrl}/${tipoDocumento}/permisoTransitoLocal`, formData),
        vigenciaPermisoTransito: async(params)  => service.put(`${apiUrl}/vigenciaPermisoTransito`, params),
        reactivar: async (id) => service.put(`${apiUrl}/${id}/reactivar`, {}),
        cambiarEstadoSuspendida: async(id)  => service.put(`${apiUrl}/${id}/cambiarEstadoSuspendida`, {}),
        excelEventos: async (params) => service.get(`${apiUrl}/excelEventos`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `Eventos.xlsx`
            }),
    }
};

export default useApiFlotillas;
