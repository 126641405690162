import useServiceFactory from "../serviceFactory";

const apiUrl = `/notificaciones`;

const useApiNotificationes = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        get: async () => service.get(`${apiUrl}`, { showLoading:false }),
        getBotonPanico: async () => service.get(`${apiUrl}/alerta-boton-panico`, { showLoading:false }),
        leidas: async (ids) => service.patch(`${apiUrl}/leidas`, ids, { showLoading:false, showSuccess:false }),
        borrar: async (ids) => service.patch(`${apiUrl}/borrar`, ids, { showLoading:false, showSuccess:false })

    }
};

export default useApiNotificationes;

