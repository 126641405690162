import React from "react";
import {dateToString, utcToString} from "../../../../shared/format";
import {tiposIconoRuta} from "../SeguimentoConstantes";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";

const InformacionRuta = ({viaje}) => {

    const {viajeSeleccionado} = useSeguimientoMonitoreo();

    const getIconPath = (tipo) =>{
        switch (tipo){
            case tiposIconoRuta.inicio:
                return '/img/monitoreo/origin.svg'
            case tiposIconoRuta.enRuta:
                return '/img/monitoreo/destination.svg'
            default:
                return '/img/monitoreo/stops.svg'
        }
    }

    return (
        <div className={'seg-info-viaje'}>
            <div className={'cliente'}>
                <span className={'gray-label'}>
                    CLIENTE
                </span>
                <span className={'gray-label'}>
                    USUARIO CREA
                </span>
                <span className={'form-value'}>
                    {viaje.cliente}
                </span>
                <span className={'form-value'}>
                    {viaje.usuario}
                </span>
               
                {viaje.idViaje &&
                    <span>
                        {viaje.idViaje} {viaje.idViajeRetorno && <> / {viaje.idViajeRetorno} (Ret.)</>}
                    </span>
            
                 }
            <span className={'gray-label'}>
                   LINK RUTA
                </span>
                <span className={'gray-label'}>
                    NUMERO CONVENIO
                </span>
                <span className={'form-value'}>
                <a href={viaje.linkRuta}
                       target={'_blank'} rel="noreferrer">
                        Localizar en Google Maps
                    </a>
                </span>
                <span className={'form-value'}>
                    {viaje.numeroConvenio}
                </span>
            </div>
         
            <div className={'viaje'}>
                <span className={'gray-label'}>
                    CARTA PORTE
                </span>             
                <span className={'gray-label'}>
                    {viaje.esSegmentado &&
                        <>
                            Segmento Actual <span className={'estatus-viaje'}>( {viaje.nombreEstatusSegmento})</span>
                        </>
                    }
                </span>
                <span className={'form-value'}>
                    {viaje.cartaPorte}
                </span>
                
                <span className={'form-value'}>
                     {viaje.esSegmentado &&
                         <>
                             {viaje.origenSegmento} =
                         </>
                     }
                </span>
                <span className={'gray-label'}>
                    UBICACIÓN ACTUALs
                </span>
                <span className={'form-value'}>
                     {viaje.esSegmentado &&
                         <>
                             {viaje.destinoSegmento}
                         </>
                     }
                </span>
                
            </div>

            <span className={'form-value'}>
                {viajeSeleccionado.ubicacion}
            </span>
            <span className={'gray-label'}>
                VIAJE
            </span>
            <div className={'seg-viaje-ruta'}>
                {viaje?.ruta != null && viaje.ruta.map((item, index) =>
                    <React.Fragment key={index}>
                        <div className={'seg-icon-ruta-container'}>
                            <div
                                className={`seg-ruta-linea ${index >= viaje.ruta.length - 1 ? 'last' : ''} ${item.finalizadoSiguiente ? '' : 'por-finalizar'}`}></div>
                            <img className="seg-icon-ruta" src={getIconPath(item.tipoIcono)} alt="viajes"/>
                        </div>
                        <div>{item.nombre}</div>
                        <div className={'fecha'}>
                            <span
                                className={'bold'}>Prog:</span> {dateToString(item.fechaSugerida, "DD/MM/YYYY hh:mm a")}
                        </div>
                        <div className={'seg-direccion-completa'}>
                            {item.direccionCompleta}
                        </div>
                        <div>
                            {item.eta ?
                                <>
                                    <span className={'bold'}>Eta:</span> {utcToString(item.eta, "DD/MM/YYYY hh:mm a")}
                                </> :
                                <>
                                    <span
                                        className={'bold'}>Real:</span> {utcToString(item.fechaReal, "DD/MM/YYYY hh:mm a")}
                                </>
                            }
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}
export default InformacionRuta;