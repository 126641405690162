import {Button} from "primereact/button";
import React, {memo, useEffect, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {getItemStyle, reorder} from "../../../../shared/DragAndDropUtility";
import ButtonIcon from "../../../../shared/controls/ButtonIcon";
import {removeArrayAtIndex, updateArrayAtIndex} from "../../../../shared/arrayUtility";
import DireccionAutocomplete from "../../../../shared/controls/DireccionAutocomplete";
import _ from "lodash";
import swal from "sweetalert2";
import {useControlMap} from "../ControlMapProvider";
import {convertirSegundosATexto, numberFormat} from "../../../../shared/format";
import {letras} from "../../../../shared/Constantes";

const initialValue = [
    {id: 1, punto: null, placeholder: 'origen'},
    {id: 2, punto: null, placeholder: 'destino'}
]
const RoutingModal = memo(() => {

    const [valid, setValid] = useState(false)
    const [puntos, setPuntos] = useState(initialValue)
    const {visibleRuteo, generarRuta, cerrarRuteo, legsMapeo, resetRuteo, lugaresRuteo} = useControlMap()
    const [result, setResult] = useState({})

    const busquedaRealizada = legsMapeo.length > 0;

    useEffect(() => {
        setPuntos(initialValue)
    }, [visibleRuteo]);

    const calcularResultado = (legs) => {
        _.sumBy(legs, 'distance.value')
        if (legs.length > 0) {
            setResult({
                km: _.sumBy(legs, 'distance.value') / 1000,
                duration: _.sumBy(legs, 'duration.value'),
            })
            return;
        }
        setResult({})
    }

    useEffect(() => {
        calcularResultado(legsMapeo)
    }, [legsMapeo]);

    const onDragEnd = ({source, destination}) => {
        const reordenado = reorder(puntos, source.index, destination.index);
        reordenado[0].placeholder = 'origen'
        for (let i = 1; i < reordenado.length - 1; i++) {
            reordenado[i].placeholder = 'punto intermedio'
        }
        reordenado[reordenado.length - 1].placeholder = 'destino'
        setPuntos(reordenado);
    }

    const onChange = (punto, index) => {
        const nuevo = updateArrayAtIndex(puntos, index, {...puntos[index], punto});
        setValid(nuevo.filter(e => e.punto == null).length === 0);
        setPuntos(nuevo);
    }

    const agregar = () => {
        const id = _.maxBy(puntos, 'id');
        const modificado = updateArrayAtIndex(puntos, puntos.length - 1, {
            ...puntos[puntos.length - 1],
            placeholder: 'punto intermedio'
        })
        setPuntos([...modificado, {id, punto: null, placeholder: 'destino'}])
        setValid(false)
    }

    const onEliminar = (index) => {
        const nuevo = removeArrayAtIndex(puntos, index);
        setPuntos(nuevo)
        setValid(nuevo.filter(e => e.punto == null).length === 0);
    }

    const handleClickRoute = async () => {
        if (!valid) {
            await swal.fire({
                titleText: 'Completar la ruta',
                text: 'Favor de seleccionar todos los puntos de la ruta ',
                icon: 'error',
            });
            return;
        }
        generarRuta(puntos)
    }

    const handleResetRuteo = () => {
        setPuntos(initialValue.map(e => {
            return {...e}
        }))
        resetRuteo()
    }

    if (!visibleRuteo) {
        return null
    }

    const getPuntosMaps = () => {
        return lugaresRuteo.map(e => `${e.latitude},${e.longitude}`).join('/')
    }

    return (
        <div style={{width: '350px', height:'700px', maxHeight:'90vh'}} className="seg-modal">
            <div className={'seg-dialog-header'}>
                <div className={'seg-dialog-title'}>
                    Buscar Rutas
                </div>
                <Button icon="pi pi-times" className="p-button-rounded p-button-text seg-dialog-close"
                        onClick={() => cerrarRuteo()}/>
            </div>
            {busquedaRealizada ?
                <div className={'routing-resultado'}>
                    {puntos && puntos.map((item, index) =>
                        <React.Fragment key={index}>
                            <div>
                                <div className={'punto'}>
                                    {letras[index]}
                                </div>
                            </div>
                            <div>
                                {item.punto?.direccion}
                            </div>
                            <div>

                            </div>
                        </React.Fragment>
                    )}
                </div> :
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                            >
                                {puntos.map((item, index) => (
                                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                className={'routing-puntos-container'}
                                            >
                                                <div className={'routing-left-icon'}>
                                                    <i className="pi pi-sort"></i>
                                                </div>
                                                <div className={'routing-text'}>
                                                    <DireccionAutocomplete
                                                        value={item.punto}
                                                        placeholder={item.placeholder}
                                                        onChange={(value) => onChange(value, index)}
                                                    />
                                                </div>
                                                <div className={'routing-delete'}>
                                                    {puntos.length > 2 &&
                                                        <ButtonIcon title={'Eliminar'}
                                                                    iconName={'RiDeleteBinLine'}
                                                                    onClick={() => onEliminar(index)}/>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                {valid && legsMapeo.length === 0 &&
                                    <div className={'routing-puntos-container'}>
                                        <div className={'routing-left-icon'}>
                                            <ButtonIcon title={'Agregar'}
                                                        iconName={'IoIosAddCircle'}
                                                        onClick={agregar}/>
                                        </div>
                                        <div className={'routing-text'} onClick={agregar}>Agregar destino</div>
                                    </div>
                                }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
            {busquedaRealizada &&
                <div className={'routing-result'}>
                    <label style={{fontWeight: 'bold'}}>
                        Resultado ruta
                    </label>
                    <label>
                        {numberFormat(result.km, 2)} km - {convertirSegundosATexto(result.duration)}
                    </label>
                    <div>
                        <a href={`https://www.google.com/maps/dir/${getPuntosMaps()}`} target={'_blank'}
                           rel="noreferrer">Abrir en Google Maps</a>
                    </div>
                </div>
            }

            <div className={'fluid'} class={'dialog-footer center-buttons'}>
                {!busquedaRealizada ?
                    <Button label="Buscar ruta" className={'p-button-sm'} onClick={handleClickRoute} autoFocus/> :
                    <Button label="Nueva búsqueda" className={'p-button-sm'} onClick={handleResetRuteo} autoFocus/>
                }
            </div>

        </div>
    )
});
export default RoutingModal;