/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import {Button} from "primereact/button";
import React, {useEffect, useReducer} from "react";
import useApiUsuarios from "../../api/catalogos/useApiUsuarios";
import formReducer from "../../shared/forms/formReducer";
import FormHandler from "../../shared/forms/formHandler";
import usuariosValidacionEsquema from "./usuariosValidacionEsquema";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import alerts from "../../shared/alerts";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";
import FieldAutoComplete from "../../shared/controls/fieldAutoComplete";

const DetalleUsuarios = ({usuarioId, onSave, close, soloLectura}) => {
    const api = useApiUsuarios();

    const defaultEntity = () => ({entity: {id: 0, activo: true}});
    const [{entity, errors}, dispatch] = useReducer(formReducer, {}, defaultEntity);

    useEffect(() => {
        async function cargar() {
            dispatch({type: "update", value: await api.obtener(usuarioId)});
        }

        if (usuarioId > 0) {
            cargar().then();
        }
    }, []);

    const actualizar = (value, propertyName) => {
        dispatch({type: "update", value: value, name: propertyName});

        //Si es un usuario que ya existe y el password esta vacío,
        // se elimina esta propiedad para que no la valide
        if (entity.id > 0 && propertyName === "password" && value === "") {
            const {["password"]: remove, ...copiaEntity} = entity;
            dispatch({type: "update", value: copiaEntity});
        }
    };

    const formHandler = new FormHandler({
        validationSchema: usuariosValidacionEsquema,
        api,
        dispatch,
    });

    const guardar = async () => {
        await formHandler.save(entity);

        if (formHandler.valdation.result) {
            onSave();
        }
    };

    const eliminar = async () => {
        if (await alerts.preguntarSiNoAdvertencia("¿Desea desactivar el usuario?")) {
            await api.eliminar(usuarioId);
            onSave();
        }
    };

    return (
        <>
            <div className="form-container full-height">
                <ReadOnlyProvider defaultReadOnly={soloLectura}>
                    <div className="form-row">
                        <h5 className="col-12">Datos Generales</h5>

                        <FieldTextBox
                            name="nombres"
                            label="Nombre"
                            colMd={12}
                            required
                            value={entity.nombres}
                            onChange={actualizar}
                            errors={errors}
                        />

                        <FieldTextBox
                            name="email"
                            label="Correo"
                            colMd={6}
                            required
                            value={entity.email}
                            onChange={actualizar}
                            errors={errors}
                        />

                        <FieldMultiSelect
                            name="perfilIds"
                            source="perfiles"
                            errors={errors}
                            required
                            showFilter
                            label="Perfil"
                            valueAsId={true}
                            colMd={6}
                            value={entity.perfilIds}
                            onChange={actualizar}
                        />

                        <FieldDropDown
                            name="patioUbicacion"
                            label="Ubicacion"
                            colMd={6}
                            filter={true}
                            source="patios"
                            required
                            errors={errors}
                            value={entity.patioUbicacion}
                            onChange={actualizar}
                        />

                        <FieldAutoComplete name="empleado" label="Empleado" colMd={6} source="empleados" filter
                                           errors={errors} className="dropdown-bottom"
                                           params={{todasLasEmpresas: true}} value={entity.empleado}
                                           onChange={actualizar}/>

                        <FieldMultiSelect
                            name="empresasIds"
                            source="todasEmpresas"
                            errors={errors}
                            required
                            showFilter
                            label="Empresa"
                            valueAsId={true}
                            colMd={6}
                            value={entity.empresasIds}
                            onChange={actualizar}
                        />
                    </div>

                    <div className="form-row">
                        <h5 className="col-12">Acceso</h5>

                        <FieldTextBox
                            name="username"
                            label="Usuario"
                            colMd={6}
                            required
                            value={entity.username}
                            onChange={actualizar}
                            errors={errors}
                        />

                        <FieldTextBox
                            name="password"
                            label="Contraseña"
                            colMd={6}
                            required
                            value={entity.password}
                            onChange={actualizar}
                            errors={errors}
                        />

                        <FieldMultiSelect
                            name="cajaIds"
                            source="cajachica/cajas"
                            required
                            filter={true}
                            label="Cajas (aplica si usuario puede ver caja chica)"
                            valueAsId={true}
                            colMd={12}
                            value={entity.cajaIds}
                            onChange={actualizar}
                        />
                       <FieldCheckBox name="activo" label="Activo" value={entity.activo}   colMd={3} onChange={actualizar}/>
                       <FieldCheckBox name="dobleFA" label="2-FA" value={entity.dobleFA} colMd={3} onChange={actualizar}/>
                       <FieldCheckBox name="inHouse" label="In House" value={entity.inHouse} colMd={3}  onChange={actualizar}/>
                       <FieldCheckBox name="alertaBotonPanico" label="Alertar Botón de Pánico" value={entity.alertaBotonPanico} colMd={6}  onChange={actualizar}/>
                     
                        {entity?.inHouse &&
                            <FieldMultiSelect
                                name="clienteIds"
                                source="clientes"
                                required
                                showFilter={true}
                                filter={true}
                                label="Clientes (Aplica si usuario es In House)"
                                valueAsId={true}
                                colMd={12}
                                value={entity.clienteIds}
                                onChange={actualizar}
                            />
                        }
                    </div>
                </ReadOnlyProvider>
            </div>
            <div className="form-footer">
                <Button label="Cancelar" className="p-button-outlined" type="button" onClick={close}/>
                {usuarioId > 0 && !soloLectura && (
                    <Button
                        label="Desactivar"
                        className="p-button-outlined p-button-danger"
                        type="button"
                        onClick={eliminar}
                    />
                )}
                {!soloLectura && <Button label="Guardar" type="button" onClick={guardar}/>}
            </div>
        </>
    );
};

export default DetalleUsuarios;
