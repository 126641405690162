import useServiceFactory from "../serviceFactory";

const apiUrl = "/administracion/capacitacion";

const useApiCapacitacion= () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`),
        guardar: async (formData) => service.post(`${apiUrl}`, formData),
        quitarEmpleadoCapacitacion: async (id, empleadoCapacitacionId) => service.delete(`${apiUrl}/${id}/empleado/${empleadoCapacitacionId}`),
        quitarDocumentoCapacitacion: async (id,documentoId) => service.delete(`${apiUrl}/${id}/capacitacion/${documentoId}`),
        excel: async (params) =>
            service.get(`${apiUrl}/excel`, {
                params,
                showLoading: true,
                download: true,
                fileName: `Capacitacion.xlsx`,
            }),
        obtenerEmpleados : async (params) => service.get(`${"source/empleadosOperativosData"}`, {params}),
   
    };
};

export default useApiCapacitacion;
