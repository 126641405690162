import React from "react";
import produce from "immer";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import constantes from "../../../catalogos/constantes";
import { useEmpresa } from "../../../layout/EmpresaProvider";

const DatosGenerales = ({
    datosGenerales,
    codigoUnidad,
    name,
    errors,
    onChange,
    onUpdateEquipo,
    onUpdateNacionalCruce,
}) => {
    const { empresa } = useEmpresa();

    async function actualizar(value, propertyName) {
        const nuevo = produce(datosGenerales ?? {}, (draft) => {
            draft[propertyName] = value;
            draft.esMotriz = draft.tipoEquipo?.id === constantes.tiposEquipo.motriz;
            if (propertyName === "empresaArrendatariaId") draft.arrendatario = "";
        });

        if (propertyName === "tipoEquipo") {
            onUpdateEquipo(nuevo);
        } else if (propertyName === "nacionalCruce") {
            onUpdateNacionalCruce(nuevo);
        } else {
            onChange(nuevo, name);
        }
    }

    return (
        <>
            <div className="form-row">
                <h5 className="col-12">Generales</h5>
                <FieldTextBox
                    name="codigoUnidad"
                    label="Id"
                    colMd={3}
                    disabled={true}
                    errors={errors}
                    value={codigoUnidad}
                />
                <FieldTextBox
                    keyFilter={/[A-Za-z0-9]/}
                    maxLength={10}
                    name="numeroEconomico"
                    label="Número Economico"
                    colMd={4}
                    errors={errors}
                    required
                    value={datosGenerales.numeroEconomico}
                    onChange={actualizar}
                />{" "}
                <h6 style={{ marginTop: "30px" }} className="col-1">
                    - {empresa.nombre}
                </h6>
                {datosGenerales.tipoEquipo?.id === constantes.tiposEquipo.motriz &&
                    <FieldTextBox
                    maxLength={15}
                    name="servicioSaas"
                    label="ServicioSaas"
                    disabled={true}
                    colMd={4}
                    errors={errors}
                    value={datosGenerales.servicioSaas}
                    onChange={actualizar}
                />
                }                
                <FieldDropDown
                    name="tipoEquipo"
                    label="Tipo de Equipo"
                    colMd={6}
                    source="tiposEquipo"
                    required
                    errors={errors}
                    showClear={false}
                    value={datosGenerales.tipoEquipo}
                    onChange={actualizar}
                />
                {datosGenerales.tipoEquipo != null && (
                    <>
                        <FieldDropDown
                            name="tipoUnidad"
                            label="Tipo"
                            colMd={6}
                            source="tiposUnidadFlotilla"
                            required
                            params={{ id: datosGenerales?.tipoEquipo?.id }}
                            errors={errors}
                            value={datosGenerales.tipoUnidad}
                            onChange={actualizar}
                        />
                        <div className={`form-group col-md-5 col-sm-12}`}>
                            <label>&nbsp;</label>
                            <FieldRadioButtonList
                                name="nacionalCruce"
                                label="NacionalCruce"
                                colMd={8}
                                valueIsObject={true}
                                source="general/NacionalCruce"
                                errors={errors}
                                value={datosGenerales.nacionalCruce}
                                onChange={actualizar}
                            />
                        </div>
                        {(datosGenerales.tipoEquipo.id === 1 || datosGenerales.tipoEquipo.id === 2) && (
                            <>
                                <FieldCheckBox
                                    name="renta"
                                    label="Renta"
                                    colMd={2}
                                    value={datosGenerales.renta}
                                    onChange={actualizar}
                                />
                            </>
                        )}
                        {datosGenerales.tipoEquipo.id === constantes.tiposEquipo.motriz && (
                            <>
                                <FieldCheckBox
                                    name="esPermisionario"
                                    label="Permisionario"
                                    colMd={2}
                                    value={datosGenerales.esPermisionario}
                                    onChange={actualizar}
                                />
                            </>
                        )}
                        <FieldCheckBox
                            name="esTransfer"
                            label="EsTransfer"
                            colMd={2}
                            value={datosGenerales.esTransfer}
                            onChange={actualizar}
                        />
                        {datosGenerales.renta === true && (
                            <>
                                <FieldDropDown
                                    name="empresaArrendatariaId"
                                    label="Arrendatario"
                                    colMd={4}
                                    source="enRentaEmpresas"
                                    filter
                                    errors={errors}
                                    valueById={true}
                                    value={datosGenerales.empresaArrendatariaId}
                                    onChange={actualizar}
                                />
                            </>
                        )}
                        {datosGenerales.renta && datosGenerales.empresaArrendatariaId === -1 && (
                            <FieldTextBox
                                maxLength={255}
                                name="arrendatario"
                                label="Arrendatario"
                                colMd={12}
                                errors={errors}
                                required={datosGenerales.renta}
                                value={datosGenerales.arrendatario}
                                onChange={actualizar}
                            />
                        )}
                        {datosGenerales.tipoEquipo.id === 1 && (
                            <FieldCheckBox
                                name="propiedadAbc"
                                label="Propiedad Abc"
                                colMd={3}
                                value={datosGenerales.propiedadAbc}
                                onChange={actualizar}
                            />
                        )}
                        <div>&nbsp;</div>
                        <h5 className="col-12">Información de Unidad</h5>
                        {datosGenerales.esMotriz === false && (
                            <>
                                <FieldDropDown
                                    name="marcaArrastre"
                                    label="Marca"
                                    colMd={6}
                                    source="marcasArrastre"
                                    filter
                                    required
                                    errors={errors}
                                    value={datosGenerales.marcaArrastre}
                                    onChange={actualizar}
                                />
                            </>
                        )}
                        {datosGenerales.esMotriz === true && (
                            <>
                                <FieldDropDown
                                    name="marcaMotriz"
                                    label="Marca"
                                    colMd={6}
                                    source="marcasMotriz"
                                    filter
                                    required
                                    errors={errors}
                                    value={datosGenerales.marcaMotriz}
                                    onChange={actualizar}
                                />
                            </>
                        )}
                        <FieldTextBox
                            name="modelo"
                            label="Año"
                            colMd={6}
                            errors={errors}
                            keyFilter={"int"}
                            required
                            value={datosGenerales.modelo}
                            onChange={actualizar}
                        />
                        {datosGenerales.esMotriz === true && (
                            <>
                                <FieldDropDown
                                    name="carroceria"
                                    label="Modelo"
                                    colMd={6}
                                    source="carrocerias"
                                    required
                                    errors={errors}
                                    params={datosGenerales.marcaMotriz}
                                    value={datosGenerales.carroceria}
                                    onChange={actualizar}
                                />
                            </>
                        )}
                        <FieldTextBox
                            name="numeroSerie"
                            label="Número de Serie"
                            colMd={6}
                            errors={errors}
                            required
                            value={datosGenerales.numeroSerie}
                            onChange={actualizar}
                        />
                        <FieldTextBox
                            name="placas"
                            label="Placas"
                            maxLength={7}
                            colMd={6}
                            errors={errors}
                            required
                            value={datosGenerales.placas}
                            onChange={actualizar}
                        />
                        <FieldDropDown
                            name="proveedorGPS"
                            label="Proveedor GPS"
                            colMd={6}
                            source="proveedoresGPS"
                            required
                            errors={errors}
                            value={datosGenerales.proveedorGPS}
                            onChange={actualizar}
                        />
                        {datosGenerales.esMotriz && (
                            <FieldTextBox
                                name="permisoSCT"
                                label="Permiso SCT"
                                colMd={6}
                                errors={errors}
                                required
                                value={datosGenerales.permisoSCT}
                                onChange={actualizar}
                            />
                        )}
                        <FieldTextBox
                            maxLength={15}
                            name="imei"
                            label="Imei"
                            colMd={5}
                            errors={errors}
                            value={datosGenerales.imei}
                            onChange={actualizar}
                        />
                        {datosGenerales?.marcaMotriz != null && (

                            <FieldDropDown
                                name="color"
                                label="Color"
                                colMd={6}
                                source="colorUnidad"
                                required
                                errors={errors}
                                value={datosGenerales.color}
                                onChange={actualizar}
                            />
                        )}
                        <FieldCheckBox
                            name="gpsFuncionando"
                            label="Gps Funcionando"
                            colMd={2}
                            value={datosGenerales.gpsFuncionando}
                            onChange={actualizar}
                        />
                        <FieldCheckBox
                            name="sensorDieselOperativo"
                            label="Sensor Diesel Funcionando"
                            colMd={2}
                            value={datosGenerales.sensorDieselOperativo}
                            onChange={actualizar}
                        />
                        <FieldTextBox
                            label="Tarjeta Circulación"
                            name="tarjetaCirculacion"
                            colMd={6}           
                            errors={errors}
                            value={datosGenerales.tarjetaCirculacion}
                            onChange={actualizar}
                        />
                        {datosGenerales.esMotriz === false && (
                            <FieldDropDown
                                name="tipoInterior"
                                label="Tipo de Interior"
                                colMd={6}
                                source="general/Tipo interior"
                                required
                                errors={errors}
                                value={datosGenerales.tipoInterior}
                                onChange={actualizar}
                            />
                        )}
                        <div>&nbsp;</div>
                        <h5 className="col-12">En Caso de Siniestro</h5>
                        <FieldDropDown
                            name="aseguradora"
                            label="Aseguradora"
                            colMd={6}
                            source="aseguradoras"
                            errors={errors}
                            required
                            value={datosGenerales.aseguradora}
                            onChange={actualizar}
                        />
                        <FieldTextBox
                            label="Teléfono"
                            colMd={6}
                            errors={errors}
                            disabled
                            value={datosGenerales.aseguradora?.telefonoAseguradoraSiniestro}
                        />
                        <div>&nbsp;</div>
                        <h5 className="col-12">Seguro</h5>
                        <FieldTextBox
                            name="polizaSeguro"
                            label="Póliza de Seguro"
                            colMd={6}
                            errors={errors}
                            required
                            value={datosGenerales.polizaSeguro}
                            onChange={actualizar}
                        />
                        <FieldTextBox
                            name="incisoPoliza"
                            label="Inciso de Póliza"
                            colMd={6}
                            errors={errors}
                            required
                            value={datosGenerales.incisoPoliza}
                            onChange={actualizar}
                        />
                        <FieldTextBox
                            name="polizaRemolque"
                            label={`Póliza de Remolque ${datosGenerales.esMotriz ? "Indistinto" : ""}`}
                            colMd={6}
                            errors={errors}
                            value={datosGenerales.polizaRemolque}
                            onChange={actualizar}
                        />
                        <FieldTextBox
                            name="endoso"
                            label="Endoso"
                            colMd={6}
                            errors={errors}
                            value={datosGenerales.endoso}
                            onChange={actualizar}
                        />
                        <FieldTextBox
                            name="agenteSeguro"
                            label="Agente de Seguro"
                            colMd={6}
                            errors={errors}
                            required
                            value={datosGenerales.agenteSeguro}
                            onChange={actualizar}
                        />
                        <FieldTextBox
                            name="telefonoAseguradora"
                            label="Teléfono de Aseguradora"
                            colMd={6}
                            errors={errors}
                            required
                            value={datosGenerales.telefonoAseguradora}
                            onChange={actualizar}
                        />
                        <FieldTextBox
                            name="polizaSeguroAmericana"
                            label="Póliza de Seguro Americana"
                            colMd={6}
                            required
                            errors={errors}
                            value={datosGenerales.polizaSeguroAmericana}
                            onChange={actualizar}
                        />
                        {datosGenerales.esMotriz === true && (
                            <FieldTextBox
                                name="polizaSeguroAccesorios"
                                label="Póliza de Seguro Accesorios"
                                colMd={6}
                                required
                                errors={errors}
                                value={datosGenerales.polizaSeguroAccesorios}
                                onChange={actualizar}
                            />
                        )}
                        <FieldCheckBox
                            name="hazmat"
                            label="Hazmat"
                            colMd={6}
                            value={datosGenerales.hazmat}
                            onChange={actualizar}
                        />
                        <FieldCheckBox
                            name="monitorear"
                            label="Monitorear"
                            colMd={6}
                            value={datosGenerales.monitorear}
                            onChange={actualizar}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default DatosGenerales;
