import Indicadores from "./indicadores/Indicadores";
import {Checkbox} from "primereact/checkbox";
import {
    MostrarRutaIcon, SeguirEnMapaIcon,
    SeparadorPuntoIcon
} from "../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import React from "react";
import {useSeguimientoMonitoreo} from "./SeguimientoMonitoreoProvider";
import {useIndicadores} from "./indicadores/IndicadoresProvider";
import {colorIndicadores, nombreColorIndicador, tipoIndicador} from "./SeguimentoConstantes";

const TarjetaViaje = ({viaje, onTooltipIndicador, onHideTooltip, soloUnidades}) => {

    const {mostrarOcultar, viajeVisible,centrarUnidad} = useSeguimientoMonitoreo();
    const {seleccionarViaje} = useSeguimientoMonitoreo();
    //viajeRutaId es el id del viaje seleccionado para mostrar la ruta de la unidad
    const {overlayOnClick, viajeRutaId} = useIndicadores()

    const onUnidadClick = (e,unidadId)=>{
        centrarUnidad(unidadId)
        e.stopPropagation()
    }

    const getBotonPanicoCss =()=>{
        if (viaje?.indicadores == null)
            return ''
        const indicador = viaje.indicadores[tipoIndicador.botonPanico]
        return indicador?.color === nombreColorIndicador.rojo ?
            "boton-panico" : ''
    }

    return (
        <div className={`seg-viaje-tarjeta ${getBotonPanicoCss()}`}>
            <div className={'seg-seleccionar'}>
                <Checkbox inputId="seleccionar" checked={viajeVisible(viaje.viajeId)}
                          onChange={() => mostrarOcultar(viaje.viajeId)}/>
            </div>
            <div className={'seg-viaje'} onClick={() => seleccionarViaje(viaje)}>
                <div className={'seg-unidad'}>
                    <span onClick={(e)=>onUnidadClick(e, viaje.unidadId)}>{viaje.unidad}</span>
                    <SeparadorPuntoIcon/>
                    <span onClick={(e)=>onUnidadClick(e, viaje.cajaId)}>{viaje.caja}</span>
                    <SeparadorPuntoIcon/>
                    <span className={'seg-operador'}>{viaje.operador}</span>
                    <div className={'seg-actions'}>
                        <span className={`${viajeRutaId === viaje?.viajeId ? 'seleccionado' : '' }`}
                            onClick={(e) => overlayOnClick(e, tipoIndicador.showRoute, viaje)}>
                            <MostrarRutaIcon/>
                        </span>
                        <span onClick={(e) => overlayOnClick(e, tipoIndicador.followInMap, viaje)}>
                            <SeguirEnMapaIcon/>
                        </span>
                    </div>
                </div>
                <div style={{display: 'flex', gap: '5px', position:'relative'}}>
                    <div className={'seg-cliente'} style={{maxWidth: viaje.subCliente ? '185px' : '320px'}}>
                        {viaje.cliente}
                    </div>
                    {viaje.subCliente &&
                        <>
                            <span>/</span>
                            <div className={'seg-cliente'} style={{maxWidth: '130px'}}>
                                {viaje.subCliente}
                            </div>
                        </>
                    }
                    <span className={'seg-expeditado'}>
                        {viaje.esExpeditado ? 'Exp' : ''}
                    </span>
                </div>
                <span className={'seg-ruta'}>
                    {viaje.ruta} <span className={'seg-carta-porte'}>({viaje.cartaPorte})</span>
                </span>
                <div className={'seg-indicadores'}>
                    <Indicadores viaje={viaje} onMouseEnter={onTooltipIndicador} onMouseLeave={onHideTooltip}
                                 onClick={overlayOnClick} soloUnidades={soloUnidades}/>
                </div>
            </div>
            <div className={`seg-alerta ${colorIndicadores[viaje.color]}`} >
                <div className={'estatus-alerta'}>

                </div>
            </div>
        </div>
    )
}
export default TarjetaViaje;