/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import React, { useEffect, useReducer, useState } from "react";
import FieldCalendar from "../../shared/controls/fieldCalendar";
import InputFile from "../../shared/controls/inputFile";
import formReducer from "../../shared/forms/formReducer";
import useApiFlotillas from "../../api/comercial/useApiFlotillas";
import FormHandler from "../../shared/forms/formHandler";
import CargaMasivaValidacionEsquema from "./CargaMasivaValidacionEsquema";
import FieldDropDown from "../../shared/controls/fieldDropDown";
const CargaMasiva = ({onSave}) => {
    const api = useApiFlotillas();
    const tipos =[
        {id:113,nombre:'Permiso Transito Local'},
        {id:122,nombre:'Permiso Especial para Carga y descarga en Matamoros, Tamaulipas'},
    ]
    
    const esquemaValidacion = useState(CargaMasivaValidacionEsquema);        

    const defaultDocument = () => ({
        entity: {}
    });

    const[{entity, errors, ...state}, dispatch] = useReducer(
        formReducer, {}, defaultDocument        
    );    

    const formHandler = new FormHandler({
        validationSchema: CargaMasivaValidacionEsquema,
        api: api,
        dispatch
    });

    const update = (value, name) => {
        dispatch({type: 'update', value, name});
    };

    useEffect(() => {
        if(state.submitted){
            formHandler.setValidacionSchema(esquemaValidacion);
            formHandler.validate(entity);
        }
    }, [entity]);

    async function subirDocumento() {
        console.log('sub')
        const { result } = await formHandler.validate(entity);
        if(result){
            if (entity.archivo?.file !== null){
                const formData = new FormData();
                formData.append('file', entity.archivo.file);
                const res = await api.subirPermisoTransitoLocal(entity.tipoDocumento.id,formData);
                const params = { vigencia: entity.vigencia, fileUrl: res.fileUrl, tipoDocumento: entity.tipoDocumento.id };
                await api.vigenciaPermisoTransito(params);
                onSave();           
            } 
        }        
    }

    return (
        <>
        <div className="form-container">
            <h5 className="col-12">Carga masiva de permisos</h5>
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col" colSpan="3">Fecha de vencimiento</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="input" colSpan="3">
                        <FieldCalendar
                        name="vigencia"
                        className="small"
                        value={entity.vigencia}
                        showButtonBar={false}
                        onChange={update}
                        error={errors?.vigencia}
                        required
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="input" colSpan="3">
                         <label htmlFor="archivo">Archivo</label>
                        <InputFile
                        name="archivo"
                        value={entity.archivo}
                        onChange={update}
                        error={errors?.archivo}
                        required
                        />
                    </td>
                    </tr>
                    <tr>
                    <td className="input" colSpan="3">
                        <FieldDropDown
                        name="tipoDocumento"
                        options={tipos}
                        errors={errors}
                        label="Tipo Documento"
                        colMd={12}
                        error={errors?.tipoDocumento}
                        filter={true}
                        required={true}
                        value={entity.tipoDocumento}
                        onChange={update}
                        />
                    </td>
                    </tr>
                </tbody>
                </table>

        </div>
        <div className="form-footer">
            <Button label="Cargar" type="button" onClick={subirDocumento}/>
        </div>
        </>
    );
};

export default CargaMasiva;